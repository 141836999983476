@charset "UTF-8";
// ==================================
// Order Processing common
// ==================================
%orderProcessingCommon {
	&.contentsTop {
		padding-top: 0;
	}
	@include mq(pc) {
		padding: 70px 0 20px;
	}
	@include mq(tab) {
		padding: 40px 0 20px;
	}
	@include mq(sp) {
		padding: 15px 0;
	}
	h3 {
		font-weight: 500;
		@include mq(pc) {
			font-size: 26px;
			font-size: 2.6rem;
			text-align: center;
			padding: 10px 0 30px;
		}
		@include mq(tab) {
			font-size: 26px;
			font-size: 2.6rem;
			text-align: center;
			padding: 10px 2% 30px;
		}
		@include mq(sp) {
			font-size: 20px;
			font-size: 2.0rem;
			br { display: none; }
		}
	}
	h4 {
		font-weight: 400;
		@include mq(pc) {
			font-size: 20px;
			font-size: 2.0rem;
			text-align: center;
			margin-bottom: 10px;
		}
		@include mq(tab) {
			font-size: 20px;
			font-size: 2.0rem;
			text-align: center;
			margin-bottom: 10px;
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			font-size: 20px;
			font-size: 2.0rem;
			margin-bottom: 5px;
			padding-right: 4%;
			padding-left: 4%;
			br { display: none; }
		}
	}
	p.t_center {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			text-align: center;
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			padding-right: 4%;
			padding-left: 4%;
		}
	}
	div.img_center {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin: 0 auto 70px;
		}
		@include mq(tab) {
			text-align: center;
			margin: 0 2% 40px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
		}
		img {
			max-width: 100%;
		}
	}
	.link1col,
	.link2col,
	.link3col {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			margin: 0 2%;
		}
		@include mq(sp) {
			margin: 0 4%;
		}
		a {
			@extend .btnL;
			display: block;
			margin: 0 auto 10px;
			background: url('/img/common/icon_tri_right_gray.svg') 95% center no-repeat;
			box-sizing: border-box;
			transition: border-radius 0.4s;
			@include mq(pc) {
				float: left;
				font-size: 18px;
				font-size: 1.8rem;
				padding: 25px 0;
			}
			@include mq(tab) {
				float: left;
				background-position: 97% center;
				padding: 25px 0;
			}
			@include mq(sp) {
				width: 96%;
				padding: 23px 0;
			}
			&:hover {
				transition: border-radius 0.4s;
				border-radius: 10px;
				background-color: $grayLL;
			}
		}
	}
	div.link3col {
		a {
			@include mq(pc) {
				width: 32%;
				margin-right: 2%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 32%;
				margin-right: 2%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
	div.link2col {
		a {
			@include mq(pc) {
				width: 48%;
				margin-right: 4%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 48%;
				margin-right: 4%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}
	div.link1col {
		a {
			@include mq(pc) {
				float: none;
				width: 32%;
				margin: 0 auto;
			}
			@include mq(tab) {
				float: none;
				width: 50%;
				margin: 0 auto;
			}
		}
	}
	div.boxListWrap {
		text-align: center;
		@include mq(pc) {
			margin: 20px auto 40px;
		}
		@include mq(tab) {
			margin: 0 2% 40px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
		}
	}
	ul.boxList {
		box-sizing: border-box;
		display: inline-block;
		border: solid 1px $grayL;
		background: $white;
		text-align: left;
		@include mq(pc) {
			max-width: 770px;
			min-width: 600px;
			padding: 20px 45px;
		}
		@include mq(tab) {
			padding: 20px 2%;
		}
		@include mq(sp) {
			padding: 20px 4%;
		}
	}
	div.leftImgWrap,
	div.rightImgWrap {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto 40px;
		}
		@include mq(tab) {
			margin: 0 2% 20px;
		}
		@include mq(sp) {
			margin: 0 4% 15px;
		}
		div.imgWrap {
			text-align: center;
			border: solid 1px $grayL;
			background: $white;
			@include mq(pc) {
				width: 52%;
				padding: 40px 0;
			}
			@include mq(tab) {
				width: 52%;
				float: left;
				padding: 40px 0;
			}
			@include mq(sp) {
				margin-bottom: 15px;
				padding: 20px 10px;
			}
			img {
				max-width: 100%;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				width: 44%;
				float: right;
			}
			@include mq(tab) {
				width: 44%;
				float: right;
			}
			@include mq(sp) {
			}
			h4 {
				text-align: left;
				border-bottom: solid 1px $grayL;
				@include mq(pc) {
					padding-bottom: 10px;
				}
				@include mq(tab) {
					padding-bottom: 10px;
				}
				@include mq(sp) {
					font-size: 14px;
					font-size: 1.4rem;
					font-weight: 500;
					margin-right: 0;
					margin-left: 0;
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
	div.leftImgWrap {
		div.imgWrap {
			@include mq(pc) {
				float: right;
			}
			@include mq(tab) {
				float: right;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
	}
	div.rightImgWrap {
		div.imgWrap {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: right;
			}
			@include mq(tab) {
				float: right;
			}
		}
	}
}


// ==================================
// EC Cart
// ==================================
div.ecCartWrap {
	@extend %orderProcessingCommon;
	div.cartTableWrap {
		@include mq(pc) {
			width: $widthPc;
			padding-top: 20px;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			padding-right: 2%;
			padding-left: 2%;
		}
		table.cartTable {
			box-sizing: border-box;
			border-top: solid 1px $grayL;
			width: 100%;
			@include mq(tab) {
				display: block;
			}
			@include mq(sp) {
				display: block;
			}
		}
		tbody {
			@include mq(sp) {
				display: block;
			}
		}
		tr {
			border-bottom: solid 1px $grayL;
			overflow: hidden;
			@include mq(tab) {
				display: block;
			}
			@include mq(sp) {
				display: block;
			}
		}
		td {
			vertical-align: middle;
			@include mq(tab) {
				display: block;
				&.adjustTd {
					display: none;
				}
			}
			@include mq(sp) {
				display: block;
				&.adjustTd {
					display: none;
				}
			}
			br.sp {
				@include mq(pc) { display: none; }
				@include mq(tab) { display: none; }
			}
		}
		td.detail {
			@include mq(pc) {
				width: 49%;
			}
			@include mq(tab) {
				width: 60%;
				float: left;
			}
			@include mq(sp) {
				width: 60%;
				float: left;
				margin-left: 4%;
			}
			a {
				overflow: hidden;
				display: block;
				width: 100%;
				@include mq(pc) {
					padding: 30px 0;
				}
				@include mq(tab) {
					padding: 20px 0;
				}
				@include mq(sp) {
					padding: 15px 0;
				}
			}
			div.img {
				background: $grayLL center center no-repeat;
				background-size: cover;
				float: left;
				&::after {
					content: '';
					display: block;
					padding-top: 100%;
				}
				@include mq(pc) {
					width: 29.66%;
				}
				@include mq(tab) {
					width: 40%;
				}
				@include mq(sp) {
					width: 47%;
				}
			}
			div.txtWrap {
				float: left;
				box-sizing: border-box;
				@include mq(pc) {
					width: 70.34%;
					padding: 15px 0 15px 30px;
				}
				@include mq(tab) {
					width: 60%;
					padding: 15px 0 15px 15px;
				}
				@include mq(sp) {
					width: 53%;
					padding: 5px 0 5px 5px;
				}
			}
			p.brand {
				color: $gray;
				font-weight: 600;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				@include mq(pc) {
					font-size: 12px;
					font-size: 1.2rem;
					margin-bottom: 5px;
				}
				@include mq(tab) {
					font-size: 12px;
					font-size: 1.2rem;
					margin-bottom: 5px;
				}
				@include mq(sp) {
					font-size: 10px;
					font-size: 1.0rem;
					width: 120%;
					transform: scale(0.8);
					transform-origin: left top;
				}
			}
			p.name {
				margin-bottom: 4px;
				line-height: 1.4;
				font-weight: 600;
				overflow: hidden;
				@include mq(pc) {
					font-size: 16px;
					font-size: 1.6rem;
					max-height: 68px;
				}
				@include mq(tab) {
					font-size: 16px;
					font-size: 1.6rem;
					max-height: 68px;
				}
				@include mq(sp) {
					font-size: 10px;
					font-size: 1.0rem;
					max-height: 29px;
				}
			}
			p.spec,
			p.size {
				overflow: hidden;
				@include mq(pc) {
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				@include mq(tab) {
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				@include mq(sp) {
					font-size: 10px;
					font-size: 1.0rem;
					width: 111%;
					transform: scale(0.9);
					transform-origin: left top;
				}
			}
		}
		td.price {
			box-sizing: border-box;
			@include mq(pc) {
				width: 18%;
				padding-left: 20px;
			}
			@include mq(tab) {
				padding-top: 35px;
			}
			@include mq(sp) {
				padding-top: 15px;
			}
		}
		td.itemNum {
			box-sizing: border-box;
			@include mq(pc) {
				width: 9%;
				padding-left: 20px;
			}
		}
		td.subtotal {
			@include mq(pc) {
				padding-left: 20px;
			}
		}
		td.price,
		td.itemNum,
		td.subtotal {
			@include mq(tab) {
				float: right;
				text-align: right;
				width: 30%;
				margin-right: 4%;
				margin-bottom: 10px;
			}
			@include mq(sp) {
				float: right;
				text-align: right;
				font-size: 10px;
				font-size: 1.0rem;
				width: 30%;
				margin-right: 4%;
				transform: scale(0.9);
				transform-origin: right top;
			}
		}
		td.itemDel {
			@include mq(pc) {
				width: 6%;
			}
			@include mq(tab) {
				display: block;
				clear: both;
				width: 100%;
			}
			@include mq(sp) {
				display: block;
				clear: both;
				width: 100%;
			}
			a {
				display: inline-block;
				width: 100%;
				text-decoration: none;
				text-align: center;
				color: $grayD;
				background: $grayL;
				@include mq(pc) {
					padding: 10px 0;
				}
				@include mq(tab) {
					padding: 15px 0;
					margin: 5px 0 15px;
				}
				@include mq(sp) {
					padding: 5px 0;
					margin: 5px 0 15px;
				}
			}
		}
		td.itemSumWrap,
		td.totalWrap {
			@include mq(pc) {
				padding: 20px 0;
			}
			@include mq(tab) {
				padding: 20px 0;
			}
			@include mq(sp) {
				padding: 15px 0;
			}
			& > div {
				overflow: hidden;
				margin-bottom: 10px;
				&:last-child {
				margin-bottom: 0;
				}
			}
			p {
				display: inline-block;
				&.title {
					float: left;
					@include mq(tab) {
						margin-left: 70%;
					}
					@include mq(sp) {
						margin-left: 4%;
					}
				}
				&.value {
					float: right;
					@include mq(sp) {
						margin-right: 4%;
					}
				}
			}
		}
		td.totalWrap {
			font-size: 18px;
			font-size: 1.8rem;
			font-weight: 600;
		}
	}

	div.link2col {
		text-align: center;
		@include mq(pc) {
			padding-top: 50px;
		}
		@include mq(tab) {
			padding-top: 50px;
		}
		@include mq(sp) {
			padding-top: 20px;
		}
		a {
			@include mq(pc) {
				width: 31.5%;
				display: inline-block;
				float: none;
			}
			@include mq(tab) {
				width: 40%;
				display: inline-block;
				float: none;
			}
			&.continueShopping {
				background-image: url('/img/common/icon_tri_left_gray.svg');
				background-position: 5% center;
			}
			&.purchaseStep {
				background-image: url('/img/common/icon_tri_right_white.svg');
				background-color: $gray;
				color: $white;
			}
		}
	}
}

// ==================================
// EC Shopping Input
// ==================================
div.ecShoppingInput {
	@extend .formWrap;
	@extend %orderProcessingCommon;
	div.stepsWrap {
		ul.steps > li {
			@include mq(pc) {
				width: 23.5%;
				&::after {
						right: -7%;
				}
			}
			@include mq(tab) {
				width: 23.5%;
				font-size: 13px;
				font-size: 1.3rem;
				&::after {
						right: -7%;
				}
			}
			@include mq(sp) {
				width: 22.37%;
				font-size: 12px;
				font-size: 1.2rem;
				text-align: center;
				&::after {
					width: 7%;
					height: 50px;
					right: -13%;
				}
				span {
					width: 200px;
					height: 38px;
					display: table-cell;
					vertical-align: middle;
					text-align: center;
				}
			}
		}
	}
	table.formInputTable {
		td.formContent.addInfo {
			@include mq(pc) {
				text-align: center;
				padding-top: 40px;
				padding-bottom: 40px;
				background-color: $grayLL;
			}
			@include mq(tab) {
				text-align: center;
				padding-top: 40px;
				padding-bottom: 40px;
				background-color: $grayLL;
			}
			@include mq(sp) {
				border-top: solid 1px $grayL;
			}
			label {
				color: $red;
				margin-right: 1em;
			}
		}
		br.sp {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none; }
		}
		input[disabled],
		select[disabled] {
			background-color: $grayLL;
		}
	}
	div.addInfoWrap {
		@include mq(sp) {
			overflow: hidden;
			max-height: 0;
			transition: max-height 0.4s;
			&.open {
				max-height: 2000px;
				transition: max-height 0.8s;
			}
		}
	}
	table.addInfoTable {
		border-top: 0;
	}
}

// ==================================
// EC Shopping Confirm
// ==================================
div.ecConfirm {
	@extend .confirmWrap;
	@extend .ecCartWrap;
	@extend .ecShoppingInput;
	& > h4,
	& form > h4 {
		background: $grayL;
		@include mq(pc) {
			margin: 40px 0 0;
			padding: 10px 0;
			font-size: 20px;
			font-size: 2.0rem;
		}
		@include mq(tab) {
			margin: 40px 0 20px;
			padding: 10px 0;
			font-size: 20px;
			font-size: 2.0rem;
		}
		@include mq(sp) {
			font-size: 14px;
			font-size: 1.4rem;
			padding: 10px 4%;
			margin-bottom: 15px;
		}
	}
	div.cartTableWrap {
		table.cartTable {
			@include mq(pc) {
				margin-bottom: 50px;
			}
			@include mq(tab) {
				margin-bottom: 40px;
			}
			@include mq(sp) {
				margin-bottom: 20px;
			}
		}
		td.detail {
			@include mq(pc) {
				width: 58%;
				div.img {
					width: 25.08%;
				}
				div.txtWrap {
					width: 74.92%;
				}
			}
			@include mq(tab) {
				width: 58%;
				div.img {
					width: 25.08%;
				}
				div.txtWrap {
					width: 74.92%;
				}
			}
			@include mq(sp) {
			}
		}
		td.subtotal {
			text-align: right;
		}
	}
	div.formInputWrap {
		@include mq(sp) {
			padding-top: 0;
			margin-bottom: 10px;
		}
	}
}




// ==================================
// EC Shopping Complete
// ==================================
div.ecShoppingComplete {
	@extend .ecShoppingInput;
	div.contactInfo {
		border-top: solid 1px $grayL;
		border-bottom: solid 1px $grayL;
		@include mq(pc) {
			width: $widthPc;
			margin: 30px auto 20px;
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@include mq(tab) {
			margin: 30px 2% 20px;
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@include mq(sp) {
			margin-bottom: 15px;
			padding-top: 15px;
			padding-bottom: 15px;
		}
		h4 {
			font-weight: 500;
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		p {
			@include mq(pc) {
				text-align: center;
			}
			@include mq(tab) {
				text-align: center;
			}
			@include mq(sp) {
				padding: 0 4%;
			}
		}
	}
}


// ==================================
// EC Shopping Deliv
// ==================================
div.ecShoppingDeliv {
	@extend .ecCartWrap;
	@extend .ecShoppingInput;

	div.introWrap {
		div.formBtnWrap {
			@include mq(sp) {
				margin-top: 15px;
				margin-bottom: 15px;
			}
		}
	}
	div.cartTableWrap table.cartTable {
		margin-bottom: 30px;
		&:first-child {
			@include mq(tab) {
				border: 0;
			}
			@include mq(sp) {
				border: 0;
			}
		}
		th {
			@include mq(pc) {
				padding: 15px 0;
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: 400;
			}
			@include mq(tab) {
				padding: 15px 0;
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: 400;
				display: none;
			}
			@include mq(sp) {
				display: none;
			}
		}
		td {
			@include mq(pc) {
				padding: 15px 0;
			}
		}
		td.select {
			text-align: center;
			@include mq(pc) {
				padding-left: 15px;
				padding-right: 15px;
			}
			@include mq(tab) {
				line-height: 50px;
				float: left;
				padding-left: 2%;
				padding-right: 2%;
			}
			@include mq(sp) {
				line-height: 50px;
				float: left;
				padding-left: 4%;
				padding-right: 4%;
			}
			span {
				margin-right: 0;
			}
		}
		td.type {
			@include mq(pc) {
				text-align: center;
				padding-left: 30px;
				padding-right: 30px;
			}
			@include mq(tab) {
				line-height: 50px;
				background: $grayLL;
				border-bottom: solid 1px $grayL;
			}
			@include mq(sp) {
				line-height: 50px;
				background: $grayLL;
				border-bottom: solid 1px $grayL;
			}
		}
		td.address {
			@include mq(pc) {
			}
			@include mq(tab) {
				box-sizing: border-box;
				width: 76%;
				padding: 4%;
				float: left;
			}
			@include mq(sp) {
				box-sizing: border-box;
				width: 76%;
				padding: 4%;
				float: left;
			}
		}
		td.edit {
			@include mq(pc) {
				width: 10%;
				padding-right: 2%;
				padding-left: 2%;
			}
			@include mq(tab) {
				display: block;
				clear: both;
				width: 100%;
			}
			@include mq(sp) {
				display: block;
				clear: both;
				width: 100%;
			}
			a {
				display: inline-block;
				width: 100%;
				text-decoration: none;
				text-align: center;
				color: inherit;
				background: $white;
				border: solid 1px $grayL;
				@include mq(pc) {
					padding: 9px 0;
				}
				@include mq(tab) {
					padding: 14px 0;
				}
				@include mq(sp) {
					padding: 4px 0;
				}
			}
		}
		td.edit,
		td.itemDel {
			@include mq(tab) {
				width: 20%;
				float: left;
				clear: none;
				margin-top: 4%;
				&:last-child {
					margin-bottom: 4%;
				}
				a {
					margin: 0;
				}
			}
			@include mq(sp) {
				width: 20%;
				float: left;
				clear: none;
				margin-top: 4%;
				&:last-child {
					margin-bottom: 4%;
				}
				a {
					margin: 0;
				}
			}
		}
	}
}




