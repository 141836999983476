@charset "UTF-8";
// ==================================
// static page common
// ==================================
%staticPageCommon {
	&.contentsTop {
		padding-top: 0;
	}
	@include mq(pc) {
		padding: 70px 0 20px;
	}
	@include mq(tab) {
		padding: 40px 0 20px;
	}
	@include mq(sp) {
		padding: 15px 0;
	}
	h3 {
		font-weight: 500;
		@include mq(pc) {
			font-size: 26px;
			font-size: 2.6rem;
			text-align: center;
			padding: 10px 0 30px;
		}
		@include mq(tab) {
			font-size: 26px;
			font-size: 2.6rem;
			text-align: center;
			padding: 10px 2% 30px;
		}
		@include mq(sp) {
			font-size: 20px;
			font-size: 2.0rem;
			padding: 0 4% 15px;
			br { display: none; }
		}
	}
	h4 {
		font-weight: 400;
		@include mq(pc) {
			font-size: 20px;
			font-size: 2.0rem;
			text-align: center;
			margin-bottom: 10px;
		}
		@include mq(tab) {
			font-size: 20px;
			font-size: 2.0rem;
			text-align: center;
			margin-bottom: 10px;
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			font-size: 20px;
			font-size: 2.0rem;
			margin-bottom: 5px;
			padding-right: 4%;
			padding-left: 4%;
			br { display: none; }
		}
	}
	p.t_center {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			text-align: center;
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			padding-right: 4%;
			padding-left: 4%;
		}
	}
	p {
		@include mq(pc) {
			margin-bottom: 40px;
		}
		@include mq(tab) {
			margin-bottom: 40px;
		}
		@include mq(sp) {
			margin-bottom: 15px;
		}
	}
	div.img_center {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin: 0 auto 70px;
		}
		@include mq(tab) {
			text-align: center;
			margin: 0 2% 40px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
		}
		img {
			max-width: 100%;
		}
	}
	div.link1col,
	div.link2col,
	div.link3col {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			margin: 0 2%;
		}
		@include mq(sp) {
			margin: 0 4%;
		}
		a {
			@extend .btnL;
			display: block;
			margin: 0 auto 10px;
			background: url('/img/common/icon_tri_right_gray.svg') 95% center no-repeat;
			box-sizing: border-box;
			transition: border-radius 0.4s;
			@include mq(pc) {
				float: left;
				font-size: 18px;
				font-size: 1.8rem;
				padding: 25px 0;
			}
			@include mq(tab) {
				float: left;
				background-position: 97% center;
				padding: 25px 0;
			}
			@include mq(sp) {
				width: 96%;
				padding: 23px 0;
			}
			&:hover {
				transition: border-radius 0.4s;
				border-radius: 10px;
				background-color: $grayLL;
			}
		}
	}
	div.link3col {
		a {
			@include mq(pc) {
				width: 32%;
				margin-right: 2%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 32%;
				margin-right: 2%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
	div.link2col {
		a {
			@include mq(pc) {
				width: 48%;
				margin-right: 4%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 48%;
				margin-right: 4%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}
	div.link1col {
		a {
			@include mq(pc) {
				float: none;
				width: 32%;
				margin: 0 auto;
			}
			@include mq(tab) {
				float: none;
				width: 50%;
				margin: 0 auto;
			}
		}
	}
	div.boxListWrap {
		text-align: center;
		@include mq(pc) {
			margin: 20px auto 40px;
		}
		@include mq(tab) {
			margin: 0 2% 40px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
		}
	}
	ul.boxList {
		box-sizing: border-box;
		display: inline-block;
		border: solid 1px $grayL;
		background: $white;
		text-align: left;
		@include mq(pc) {
			max-width: 770px;
			min-width: 600px;
			padding: 20px 45px;
		}
		@include mq(tab) {
			padding: 20px 2%;
		}
		@include mq(sp) {
			padding: 20px 4%;
		}
	}
	div.leftImgWrap,
	div.rightImgWrap {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto 40px;
		}
		@include mq(tab) {
			margin: 0 2% 20px;
		}
		@include mq(sp) {
			margin: 0 4% 15px;
		}
		div.imgWrap {
			text-align: center;
			border: solid 1px $grayL;
			background: $white;
			@include mq(pc) {
				width: 52%;
				padding: 40px 0;
			}
			@include mq(tab) {
				width: 52%;
				float: left;
				padding: 40px 0;
			}
			@include mq(sp) {
				margin-bottom: 15px;
				padding: 20px 10px;
			}
			img {
				max-width: 100%;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				width: 44%;
				float: right;
			}
			@include mq(tab) {
				width: 44%;
				float: right;
			}
			@include mq(sp) {
			}
			h4 {
				text-align: left;
				border-bottom: solid 1px $grayL;
				@include mq(pc) {
					padding-bottom: 10px;
				}
				@include mq(tab) {
					padding-bottom: 10px;
				}
				@include mq(sp) {
					font-size: 14px;
					font-size: 1.4rem;
					font-weight: 500;
					margin-right: 0;
					margin-left: 0;
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
	div.leftImgWrap {
		div.imgWrap {
			@include mq(pc) {
				float: right;
			}
			@include mq(tab) {
				float: right;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
	}
	div.rightImgWrap {
		div.imgWrap {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: right;
			}
			@include mq(tab) {
				float: right;
			}
		}
	}
}

// ==================================
// reform page
// ==================================
div.reformWrap {
	@extend %staticPageCommon;
	&.contentsTop .img_center {
		@include mq(sp) {
			margin-right: 0;
			margin-left: 0;
		}
	}
	div.img_padding {
		box-sizing: border-box;
		border: solid 1px $grayL;
		background: $white;
		@include mq(pc) {
			text-align: center;
			width: 770px;
			margin: 0 auto 70px;
			padding: 50px 20px;
		}
		@include mq(tab) {
			text-align: center;
			margin: 0 2% 40px;
			padding: 20px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
			padding: 20px;
		}
		img {
			max-width: 100%;
		}
	}
	div.link3col {
		@include mq(pc) {
			padding-bottom: 70px;
		}
		@include mq(tab) {
			padding-bottom: 70px;
		}
		@include mq(sp) {
			padding-bottom: 30px;
		}
	}
}
div#reform_diamond,
div#reform_pearl {
	background: $grayLL;
}


// ==================================
// purchase page
// ==================================
div.purchaseWrap {
	@extend %staticPageCommon;
	&.contentsTop .img_center {
		@include mq(pc) {
			margin-top: 30px;
			margin-bottom: 30px;
		}
		@include mq(tab) {
			margin-top: 30px;
			margin-bottom: 30px;
		}
		@include mq(sp) {
			margin-right: 0;
			margin-left: 0;
		}
	}
	div.img_center_s {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin: 0 auto 40px;
		}
		@include mq(tab) {
			text-align: center;
			margin: 0 2% 20px;
		}
		@include mq(sp) {
			margin: 0 25% 15px;
		}
		img {
			max-width: 100%;
		}
	}
	h3.proposal {
		background: $grayLL;
		@include mq(pc) {
			padding-top: 20px;
			padding-bottom: 20px;
			margin-bottom: 30px;
		}
		@include mq(tab) {
			padding-top: 20px;
			padding-bottom: 20px;
			margin-bottom: 30px;
		}
		@include mq(sp) {
			padding-top: 15px;
			padding-bottom: 15px;
			margin-bottom: 15px;
		}
	}
	h4.proposal {
		@include mq(pc) {
			margin-bottom: 30px;
		}
		@include mq(tab) {
			margin-bottom: 30px;
		}
		@include mq(sp) {
			font-size: 14px;
			margin-bottom: 15px;
			font-size: 1.4rem;
		}
	}
	div.link1col {
		clear: both;
		@include mq(pc) {
			padding-top: 80px;
		}
		@include mq(tab) {
			padding-top: 80px;
		}
		@include mq(sp) {
			padding-top: 30px;
		}
	}
}


// ==================================
// Interview page
// ==================================
div.interviewWrap {
	@extend %staticPageCommon;
	div.movie {
		position: relative;
		@include mq(pc) {
			width: 770px;
			margin: 0 auto;
		}
		@include mq(tab) {
			width: 77%;
			margin: 0 auto;
		}
		@include mq(sp) {
			width: 100%;
			margin: 0 auto;
		}
		&::before {
			content: '';
			display: block;
			padding-top: 56.25%;
		}
		iframe {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			width: 100% !important;
			height: 100% !important;
		}
	}
	div.link2col {
		clear: both;
		@include mq(pc) {
			width: 770px;
			padding-top: 80px;
		}
		@include mq(tab) {
			padding-top: 80px;
		}
		@include mq(sp) {
			padding-top: 30px;
			font-size: 13px;
			font-size: 1.3rem;
		}
	}
}


// ==================================
// Concierge page
// ==================================
div.conciergeWrap {
	@extend %staticPageCommon;
	div.img_center {
		@include mq(sp) {
			margin-right: 0;
			margin-left: 0;
		}
	}
	ul.colList {
		h3 {
			padding-bottom: 0;
			@include mq(pc) {
				text-align: center;
				font-size: 20px;
				font-size: 2.0rem;
				font-weight: 300;
			}
			@include mq(tab) {
				text-align: center;
				font-size: 20px;
				font-size: 2.0rem;
				font-weight: 300;
			}
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
				padding-right: 0;
				padding-left: 0;
			}
		}
	}
	ul.colList + .link1col {
		margin-top: 40px;
	}
}

// ==================================
// Wig page
// ==================================
div.wigWrap {
	@extend %staticPageCommon;
	div.img_center {
		@include mq(sp) {
			margin-right: 0;
			margin-left: 0;
		}
	}
}


// ==================================
// Use page
// ==================================
div.useWrap {
	@extend %staticPageCommon;
	div.useContents {
		@include mq(pc) {
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			padding-right: 4%;
			padding-left: 4%;
		}
	}
	ol {
		list-style: none;
		li {
			margin-bottom: 30px;
		}
		h4{
			text-align: left;
			border-bottom: solid 1px $grayL;
			padding: 0 0 5px;
			font-weight: 500;
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
	}
}


// ==================================
// Sctl page
// ==================================
div.sctlWrap {
	@extend %staticPageCommon;
	div.sctlContents {
		border-top: solid 1px $grayL;
		border-bottom: solid 1px $grayL;
		@include mq(pc) {
			width: $widthPc;
			margin-top: 30px;
			margin-right: auto;
			margin-left: auto;
			padding: 40px 0;
		}
		@include mq(tab) {
			padding: 20px 2%;
		}
		@include mq(sp) {
			padding: 15px 4%;
		}
		table {
			th {
				text-align: left;
				font-weight: 500;
				min-width: 70px;
				padding: 5px 15px 5px 0;
				@include mq(pc) {
					padding-right: 30px;
				}
				@include mq(tab) {
					padding-right: 30px;
				}
			}
			td {
				padding: 5px 15px 5px 0;
			}
		}
	}
}


// ==================================
// Privacy page
// ==================================
div.privacyWrap {
	@extend .useWrap;
}


// ==================================
// 404 page
// ==================================
div.notfoundWrap {
	@extend .useWrap;
	h2 {
		font-family: $fontBase;
		@include mq(pc) {
			font-size: 60px;
			font-size: 6.0rem;
			padding: 50px 0 0;
		}
		@include mq(tab) {
			font-size: 60px;
			font-size: 6.0rem;
			padding: 50px 0 0;
		}
		@include mq(sp) {
			font-size: 45px;
			font-size: 4.5rem;
			padding: 40px 0 0;
		}
	}
	h3 {
		font-weight: 100;
		text-align: center;
		letter-spacing: normal;
	}
	div.notfoundIcon {
		background: url('/img/common/icon_404.svg') center center no-repeat;
		background-size: contain;
		width: 85px;
		height: 85px;
		margin: 0 auto;
	}
	p.content {
		@include mq(pc) {
			width: $widthPc;
			text-align: center;
			margin: 40px auto;
		}
		@include mq(tab) {
			margin: 40px 2%;
			text-align: center;
		}
		@include mq(sp) {
			margin: 20px 4%;
		}
	}

}
// Item Not Found
div#itemlistResultWrap.notfoundWrap {
	@include mq(pc) {
		padding-top: 20px;
	}
	@include mq(tab) {
		padding-top: 20px;
	}
	@include mq(sp) {
		padding-top: 0;
	}
	h2 {
		padding-top: 0;
	}
}
