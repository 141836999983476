@charset "UTF-8";
// ==================================
// fixed page common
// ==================================
%fixedPageCommon {
	&.contentsTop {
		padding-top: 0;
	}
	@include mq(pc) {
		padding: 70px 0 20px;
	}
	@include mq(tab) {
		padding: 40px 0 20px;
	}
	@include mq(sp) {
		padding: 15px 0;
	}
	h3 {
		font-weight: 500;
		@include mq(pc) {
			font-size: 26px;
			font-size: 2.6rem;
			text-align: center;
			padding: 10px 0 30px;
		}
		@include mq(tab) {
			font-size: 26px;
			font-size: 2.6rem;
			text-align: center;
			padding: 10px 2% 30px;
		}
		@include mq(sp) {
			font-size: 20px;
			font-size: 2.0rem;
			padding: 0 4% 15px;
			br { display: none; }
		}
	}
	h4 {
		font-weight: 400;
		@include mq(pc) {
			font-size: 20px;
			font-size: 2.0rem;
			text-align: center;
			margin-bottom: 10px;
		}
		@include mq(tab) {
			font-size: 20px;
			font-size: 2.0rem;
			text-align: center;
			margin-bottom: 10px;
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			font-size: 20px;
			font-size: 2.0rem;
			margin-bottom: 5px;
			padding-right: 4%;
			padding-left: 4%;
			br { display: none; }
		}
	}
	p.t_center {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			text-align: center;
			padding-right: 2%;
			padding-left: 2%;
		}
		@include mq(sp) {
			padding-right: 4%;
			padding-left: 4%;
		}
	}
	p {
		@include mq(pc) {
			margin-bottom: 40px;
		}
		@include mq(tab) {
			margin-bottom: 40px;
		}
		@include mq(sp) {
			margin-bottom: 15px;
		}
	}
	div.img_center {
		@include mq(pc) {
			text-align: center;
			width: $widthPc;
			margin: 0 auto 70px;
		}
		@include mq(tab) {
			text-align: center;
			margin: 0 2% 40px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
		}
		img {
			max-width: 100%;
		}
	}
	.link1col,
	.link2col,
	.link3col {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			margin: 0 2%;
		}
		@include mq(sp) {
			margin: 0 4%;
		}
		a {
			@extend .btnL;
			display: block;
			margin: 0 auto 10px;
			background: url('/img/common/icon_tri_right_gray.svg') 95% center no-repeat;
			box-sizing: border-box;
			transition: border-radius 0.4s;
			@include mq(pc) {
				float: left;
				font-size: 18px;
				font-size: 1.8rem;
				padding: 25px 0;
			}
			@include mq(tab) {
				float: left;
				background-position: 97% center;
				padding: 25px 0;
			}
			@include mq(sp) {
				width: 96%;
				padding: 23px 0;
			}
			&:hover {
				transition: border-radius 0.4s;
				border-radius: 10px;
				background-color: $grayLL;
			}
		}
	}
	div.link3col {
		a {
			@include mq(pc) {
				width: 32%;
				margin-left: 2%;
				&:nth-child(3n + 1) {
					margin-left: 0;
				}
				&.nopre {
					margin-left: 34%;
				}
			}
			@include mq(tab) {
				width: 32%;
				margin-left: 2%;
				&:nth-child(3n + 1) {
					margin-left: 0;
				}
				&.nopre {
					margin-left: 34%;
				}
			}
		}
	}
	div.link2col {
		a {
			@include mq(pc) {
				width: 48%;
				margin-right: 4%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 48%;
				margin-right: 4%;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
		}
	}
	div.link1col {
		a {
			@include mq(pc) {
				float: none;
				width: 32%;
				margin: 0 auto;
			}
			@include mq(tab) {
				float: none;
				width: 50%;
				margin: 0 auto;
			}
		}
	}
	div.boxListWrap {
		text-align: center;
		@include mq(pc) {
			margin: 20px auto 40px;
		}
		@include mq(tab) {
			margin: 0 2% 40px;
		}
		@include mq(sp) {
			margin: 0 4% 30px;
		}
	}
	ul.boxList {
		box-sizing: border-box;
		display: inline-block;
		border: solid 1px $grayL;
		background: $white;
		text-align: left;
		@include mq(pc) {
			max-width: 770px;
			min-width: 600px;
			padding: 20px 45px;
		}
		@include mq(tab) {
			padding: 20px 2%;
		}
		@include mq(sp) {
			padding: 20px 4%;
		}
	}
	div.leftImgWrap,
	div.rightImgWrap {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto 40px;
		}
		@include mq(tab) {
			margin: 0 2% 20px;
		}
		@include mq(sp) {
			margin: 0 4% 15px;
		}
		div.imgWrap {
			text-align: center;
			border: solid 1px $grayL;
			background: $white;
			@include mq(pc) {
				width: 52%;
				padding: 40px 0;
			}
			@include mq(tab) {
				width: 52%;
				float: left;
				padding: 40px 0;
			}
			@include mq(sp) {
				margin-bottom: 15px;
				padding: 20px 10px;
			}
			img {
				max-width: 100%;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				width: 44%;
				float: right;
			}
			@include mq(tab) {
				width: 44%;
				float: right;
			}
			@include mq(sp) {
			}
			h4 {
				text-align: left;
				border-bottom: solid 1px $grayL;
				@include mq(pc) {
					padding-bottom: 10px;
				}
				@include mq(tab) {
					padding-bottom: 10px;
				}
				@include mq(sp) {
					font-size: 14px;
					font-size: 1.4rem;
					font-weight: 500;
					margin-right: 0;
					margin-left: 0;
					padding-right: 0;
					padding-left: 0;
				}
			}
		}
	}
	div.leftImgWrap {
		div.imgWrap {
			@include mq(pc) {
				float: right;
			}
			@include mq(tab) {
				float: right;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
	}
	div.rightImgWrap {
		div.imgWrap {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: right;
			}
			@include mq(tab) {
				float: right;
			}
		}
	}
}


// ==================================
// Shopinfo page
// ==================================
div.shopinfoWrap {
	@extend %fixedPageCommon;
	&.contentsTop {
		@include mq(sp) {
			padding-bottom: 0;
		}
	}
	@include mq(sp) {
		div.img_center {
			margin-right: 0;
			margin-left: 0;
			margin-bottom: 0;
		}
	}
	&.mapWrap {
		background: $grayLL;
		div.rightImgWrap div.imgWrap {
			border: 0;
			background: none;
			@include mq(pc) {
				padding: 0;
			}
			@include mq(tab) {
				padding: 0;
			}
			@include mq(sp) {
				padding: 0;
			}
			div.gmap {
				position: relative;
				&::before {
					content: '';
					display: block;
					padding-top: 61.66%
				}
				iframe {
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					width: 100% !important;
					height: 100% !important;
				}
			}
			.link1col {
				@include mq(pc) { display: none; }
				@include mq(tab) { display: none; }
				@include mq(sp) {
					width: 100%;
					margin: 15px 0 30px;
					a {
						background-color: $white;
						margin: 0;
						width: 100%;
					}
				}
			}
		}
		h3 {
			text-align: left;
			vertical-align: middle;
			@include mq(sp) {
				font-weight: 400;
			}
			img {
				margin-right: 15px;
				@include mq(sp) {
					height: 28px;
					width: auto;
				}
			}
		}
	}
	.colList {
		@include mq(sp) {
			div.logo img {
				width: auto;
				height: 40px;
			}
		}
		.logo {
			padding-top: 5px;
			text-align: center;
			img {
				vertical-align: middle;
				max-width: 100%;
			}
		}
		.title {
			border-bottom: solid 1px $grayL;
			@include mq(pc) {
				font-size: 16px;
				font-size: 1.6rem;
				padding-bottom: 20px;
			}
			@include mq(tab) {
				font-size: 16px;
				font-size: 1.6rem;
				padding-bottom: 20px;
			}
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
				padding: 5px 0;
			}
		}
	}
}

// ==================================
// Company page
// ==================================
div.companyWrap {
	@extend %fixedPageCommon;
	ul.colList {
		@include mq(sp) {
			border: 0;
			& > li {
				width: 44%;
				margin: 4% 4% 0;
				overflow: hidden;
				&:nth-child(2n) {
					margin-left: 0;
				}
				a {
					border: 0;
					div.img {
						width: 100%;
					}
					div.txtWrap {
						width: 100%;
					}
					div.logo img {
						width: auto;
						height: 40px;
					}
				}
			}
		}
		.logo {
			padding-top: 5px;
			text-align: center;
			img {
				vertical-align: middle;
				max-width: 100%;
			}
		}
	}
	div.companyTableWrap {
		border-top: solid 1px $grayL;
		border-bottom: solid 1px $grayL;
		@include mq(pc) {
			width: $widthPc;
			margin-top: 30px;
			margin-right: auto;
			margin-left: auto;
			padding: 40px 0;
		}
		@include mq(tab) {
			padding: 20px 2%;
		}
		@include mq(sp) {
			padding: 15px 4%;
		}
		table {
			th {
				text-align: left;
				font-weight: 500;
				min-width: 70px;
				padding: 5px 15px 5px 0;
				@include mq(pc) {
					padding-right: 30px;
					padding-left: 20px;
				}
				@include mq(tab) {
					padding-right: 30px;
					padding-left: 20px;
				}
			}
			td {
				padding: 5px 15px 5px 0;
			}
		}
	}
}


// ==================================
// Q and A page
// ==================================
div.qaWrap {
	@extend %fixedPageCommon;
	ul.qaList {
		@include mq(pc) {
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		& > li {
			@include mq(pc) {
				padding-bottom: 30px;
			}
			@include mq(tab) {
				padding-bottom: 30px;
				padding-right: 2%;
				padding-left: 2%;
			}
			@include mq(sp) {
				padding-bottom: 15px;
			}
			&.open {
				div.ansWrap {
					max-height: 1000px;
					transition: max-height 0.6s;
				}
				.qTitle::before {
					transition: transform 0.4s;
					transform: rotate(-90deg);
				}
			}
		}
		h3 {
			text-align: left;
			@extend %clearfix;
			@include mq(pc) {
				font-size: 20px;
				font-size: 2.0rem;
				padding: 15px;
			}
			@include mq(tab) {
				font-size: 18px;
				font-size: 1.8rem;
				padding: 15px;
			}
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
				padding: 5px 4%;
			}
			span.icon {
				display: block;
				float: left;
				box-sizing: border-box;
				font-weight: 300;
				text-align: center;
				@include mq(pc) {
					font-size: 28px;
					font-size: 2.8rem;
					width: 60px;
					height: 60px;
					line-height: 60px;
					margin-right: 20px;
				}
				@include mq(tab) {
					font-size: 28px;
					font-size: 2.8rem;
					width: 60px;
					height: 60px;
					line-height: 60px;
					margin-right: 20px;
				}
				@include mq(sp) {
					font-size: 18px;
					font-size: 1.8rem;
					width: 35px;
					height: 35px;
					line-height: 35px;
				}
			}
			span.txt {
				display: block;
				@include mq(pc) {
					padding: 16px 30px 0 60px;
				}
				@include mq(tab) {
					padding: 16px 30px 0 60px;
				}
				@include mq(sp) {
					padding: 8px 20px 0 42px;
				}
			}
			&.qTitle {
				border-top: solid 1px $grayL;
				border-bottom: solid 1px $grayL;
				position: relative;
				overflow: hidden;
				cursor: pointer;
				&::before {
					content: '';
					display: block;
					position: absolute;
					background: url('/img/common/icon_arrow_right_gray.svg') center center no-repeat;
					top: 0;
					right: 0;
					bottom: 0;
					height: 100%;
					transition: transform 0.4s;
					transform: rotate(90deg);
					@include mq(pc) {
						width: 60px;
					}
					@include mq(tab) {
						width: 60px;
					}
					@include mq(sp) {
						width: 30px;
						background-size: 8px;
					}
				}
			}
			&.aTitle {
				padding-top: 15px;
				span.icon {
					border: solid 1px $grayL;
				}
				span.txt {
					padding-right: 0;
				}
			}
		}
		div.ansWrap {
			max-height: 0px;
			overflow: hidden;
			transition: max-height 0.6s;
			p {
				@include mq(pc) {
					padding-left: 90px;
					.sp { display: none; }
				}
				@include mq(tab) {
					padding-left: 90px;
					.sp { display: none; }
				}
				@include mq(sp) {
					padding: 5px 4% 0;
					.pc { display: none; }
					.link1col {
						display: inline-block;
						margin: 15px 0 0;
						width: 100%;
					}
				}
			}
		}
	}
}


// ==================================
// Voice page
// ==================================
div.voiceWrap {
	@extend %fixedPageCommon;
	ul.voiceContentsWrap {
		& > li {
			&:nth-child(odd) {
				background-color: $grayLL;
			}
			&:nth-child(even) div.voiceContent {
				div.imgWrap {
					@include mq(pc) {
						float: right;
						margin-right: 0;
					}
					@include mq(tab) {
						float: right;
						margin-right: 0;
					}
				}
				div.imgDetail {
					@include mq(pc) {
						float: right;
						margin-left: 4%;
					}
					@include mq(tab) {
						float: right;
						margin-left: 4%;
					}
				}
			}
			div.voiceContent {
				@extend div.parts_cart;
				@include mq(sp) {
					padding-top: 20px;
				}
			}
			div.detailWrap {
				@include mq(pc) {
					padding-top: 40px;
				}
				@include mq(tab) {
					padding-top: 40px;
				}
				@include mq(sp) {
					padding-top: 0;
				}
				a {
					color: $fontColor;
				}
			}
			h4 {
				text-align: left;
				@include mq(pc) {
				}
				@include mq(tab) {
				}
				@include mq(sp) {
					padding: 0 0 15px;
				}
			}
			.voiceTxtWrap {
				&:before {
					content: '';
					display: block;
					border-top: solid 1px $grayL;
				}
				@include mq(pc) {
					&:before {
						padding-top: 40px;
					}
				}
				@include mq(tab) {
					&:before {
						padding-top: 20px;
					}
				}
				@include mq(sp) {
					margin: 0 4%;
					overflow: hidden;
					transition: max-height 0.4s;
					max-height: 0;
					&:before {
						padding-top: 15px;
					}
				}
			}
			@include mq(sp) {
				.readMore {
					position: relative;
					&::before {
						content: '続きを見る';
						display: block;
						text-align: center;
						background: $white;
						border: solid 1px $grayL;
						padding: 15px 0;
						margin: 0 4% 15px;
					}
					&::after {
						content: '';
						display: block;
						position: absolute;
						background: url('/img/common/icon_tri_right_gray.svg') center center no-repeat;
						background-size: 10px;
						top: 0;
						right: 10%;
						bottom: 0;
						width: 20px;
						height: 20px;
						margin: auto;
						transition: transform 0.4s;
						transform: rotate(90deg);
					}
				}
			}
			div.voiceContent.open {
				.voiceTxtWrap {
					transition: max-height 0.8s;
					max-height: 1000px;
				}
				.readMore {
					&::before {
						content: '閉じる';
					}
					&::after {
						transition: transform 0.4s;
						transform: rotate(-90deg);
					}
				}
			}
		}
	}
}

// ==================================
// Topics Detail page
// ==================================
div.topicsDetailWrap {
	@extend %fixedPageCommon;
	div.topicsDetailContents {
		@extend %clearfix;
		box-sizing: border-box;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			padding: 0 2%;
		}
		@include mq(sp) {
		}
	}
	div.topicsDetailLeft {
		box-sizing: border-box;
		@include mq(pc) {
			float: left;
			width: 74%;
			border: solid 1px $grayL;
			padding: 4%;
		}
		@include mq(tab) {
			float: left;
			width: 76%;
			border: solid 1px $grayL;
			padding: 2%;
		}
		@include mq(sp) {
			border-top: solid 1px $grayL;
			margin-top: 5px;
			padding: 20px 4%;
		}
		div.date {
			border: solid 1px $grayL;
			text-align: center;
			@include mq(pc) {
				width: 170px;
				padding: 5px 0;
			}
			@include mq(tab) {
				width: 170px;
				padding: 5px 0;
			}
			@include mq(sp) {
				width: 110px;
				font-size: 10px;
				font-size: 1.0rem;
				padding: 3px 0;
				margin-bottom: 20px;
			}
		}
		h3 {
			text-align: left;
			@include mq(sp) {
				padding-right: 0;
				padding-left: 0;
			}
		}
		div.thumb {
			text-align: center;
			@include mq(pc) {
				margin-bottom: 45px;
			}
			@include mq(tab) {
				margin-bottom: 45px;
			}
			@include mq(sp) {
				margin-bottom: 20px;
			}
			img {
				max-width: 100%;
			}
		}
		div.content {
			img {
				max-width: 100%;
			}
		}
		.link3col {
			width: 100%;
			@include mq(pc) {
				.sp { display: none; }
			}
			@include mq(tab) {
				margin-right: 0;
				margin-left: 0;
				.sp { display: none; }
			}
			@include mq(sp) {
				.pc { display: none; }
				width: 100%;
				margin: 0 0 40px;
				padding: 0;
				a {
					float: left;
					box-sizing: border-box;
					width: 32%;
					margin-left: 2%;
					background-size: 10px;
					&:nth-child(3n + 1) {
						margin-left: 0;
					}
					&.nopre {
						margin-left: 34%;
					}
				}
			}
			.next {
				@include mq(sp) {
					background-position: 90% center;
				}
			}
			.prev {
				background-image: url('/img/common/icon_tri_left_gray.svg');
				@include mq(pc) {
					background-position: 5% center;
				}
				@include mq(tab) {
					background-position: 5% center;
				}
				@include mq(sp) {
					background-position: 10% center;
				}
			}
			.list {
				background-image: none;
			}
		}
	}
	div.topicsDetailRight {
		@include mq(pc) {
			float: right;
			width: 23%;
		}
		@include mq(tab) {
			float: right;
			width: 23%;
		}
		h4 {
			@extend .ffHalant;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				padding: 5px 0;
				border-top: solid 1px $grayL;
				border-bottom: solid 1px $grayL;
			}
			@include mq(tab) {
				font-size: 18px;
				font-size: 1.8rem;
				padding: 5px 0;
				border-top: solid 1px $grayL;
				border-bottom: solid 1px $grayL;
			}
			@include mq(sp) {
				text-align: center;
				padding: 15px 4%;
				border-top: solid 1px $grayL;
				border-bottom: solid 1px $grayL;
			}
		}
	}
	ul.recentList {
		& > li {
			@include mq(pc) {
			margin-top: 20px;
			}
			@include mq(tab) {
			margin-top: 20px;
			}
			a {
				@extend %clearfix;
				text-decoration: none;
				color: $fontColor;
				display: block;
				@include mq(sp) {
					padding: 4%;
					border-bottom: solid 1px $grayL;
				}
			}
			div.thumb {
				background: $white center center no-repeat;
				background-size: cover;
				margin-right: 3%;
				float: left;
				@include mq(pc) {
					width: 37%;
				}
				@include mq(tab) {
					width: 37%;
				}
				@include mq(sp) {
					width: 40%;
				}
				&::before {
					content: '';
					display: block;
					padding-top: 70%;
				}
			}
			div.txtWrap {
				float: left;
				overflow: hidden;
				@include mq(pc) {
					width: 60%;
				}
				@include mq(tab) {
					width: 60%;
				}
				@include mq(sp) {
					width: 57%;
				}
			}
			div.date {
				font-size: 10px;
				font-size: 1.0rem;
			}
			h5 {
				font-size: 12px;
				font-size: 1.2rem;
				font-weight: 400;
				overflow: hidden;
				height: 58px;
			}
		}
	}

}


// ==================================
// Topics List page
// ==================================
div.topicsListWrap {
	@extend %fixedPageCommon;
	ul.colList {
		margin-top: 20px;
		p {
			margin-bottom: 0;
		}
	}
}


