@charset "UTF-8";
// ==================================
// form common
// ==================================
div.formWrap,
div.confirmWrap,
div.completeWrap {

	div.stepsWrap {
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			margin: 0 2%;
		}
		ul.steps {
			@extend %clearfix;
			padding: 20px 0;
			& > li {
				box-sizing: border-box;
				float: left;
				text-align: center;
				border: solid 1px $gray;
				position: relative;
				@include mq(pc) {
					width: 32%;
					margin-right: 2%;
					font-size: 16px;
					font-size: 1.6rem;
					padding: 20px 0;
					&::after {
						width: 4.25%;
						height: 65px;
						right: -5.25%;
					}
					span.sp { display: none; }
				}
				@include mq(tab) {
					width: 32%;
					margin-right: 2%;
					font-size: 16px;
					font-size: 1.6rem;
					padding: 20px 0;
					&::after {
						width: 4.25%;
						height: 65px;
						right: -5.75%;
					}
					span.sp { display: none; }
				}
				@include mq(sp) {
					width: 31%;
					margin-right: 3.5%;
					font-size: 14px;
					font-size: 1.4rem;
					padding: 5px 0;
					&::after {
						width: 6.29%;
						height: 34px;
						right: -9.5%;
					}
					span.pc { display: none; }
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					background: url('/img/common/icon_arrow_right_gray.svg') center center no-repeat;
					background-size: contain;
					top: 0;
				}
				&:last-child {
					margin-right: 0;
					&::after {
						content: none;
					}
				}
				&.active {
					background: $gray;
					color: $white;
				}
			}
		}
	}

	div.introWrap {
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 20px 0;
			text-align: center;
		}
		@include mq(tab) {
			margin: 0 2%;
			padding: 20px 0;
			text-align: center;
		}
		@include mq(sp) {
			margin: 0 4%;
			padding: 15px 0;
		}
		h3 {
			@include mq(pc) {
				font-size: 20px;
				font-size: 2.0rem;
			}
			@include mq(tab) {
				font-size: 20px;
				font-size: 2.0rem;
			}
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
			}
			margin-bottom: 10px;
		}
	}

	div.formInputWrap {
		padding: 20px 0;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			margin: 0 2%;
		}
		@include mq(sp) {
			margin: 0;
		}
	}

	table.formInputTable {
		width: 100%;
		border-top: solid 1px $grayL;
		@include mq(sp) {
			border-bottom: solid 1px $grayL;
		}
		th.formTitle {
			font-weight: 100;
			color: $grayD;
			text-align: left;
			@include mq(pc) {
				width: 34%;
				padding: 20px 20px 20px 0;
				font-size: 16px;
				font-size: 1.6rem;
				vertical-align: middle;
				border-bottom: solid 1px $grayL;
			}
			@include mq(tab) {
				width: 34%;
				padding: 20px 20px 20px 0;
				font-size: 16px;
				font-size: 1.6rem;
				vertical-align: middle;
				border-bottom: solid 1px $grayL;
			}
			@include mq(sp) {
				display: block;
				padding: 15px 4%;
				background: $grayLL;
				border-top: solid 1px $grayL;
			}
			span.title.require::after {
				content: '必須';
				display: inline-block;
				background: $grayD url('/img/common/icon_require.svg') 5px center no-repeat;
				color: $white;
				font-weight: 100;
				@include mq(pc) {
					font-size: 12px;
					font-size: 1.2rem;
					padding: 0 7px 0 25px;
					line-height: 25px;
					margin-left: 15px;
				}
				@include mq(tab) {
					font-size: 12px;
					font-size: 1.2rem;
					padding: 0 7px 0 25px;
					line-height: 25px;
					margin-left: 15px;
				}
				@include mq(sp) {
					font-size: 10px;
					font-size: 1.0rem;
					padding: 0 7px 0 25px;
					line-height: 20px;
					margin-left: 10px;
				}
			}
		}
		td.formContent {
			@include mq(pc) {
				width: 66%;
				padding: 20px 0;
				border-bottom: solid 1px $grayL;
			}
			@include mq(tab) {
				width: 66%;
				padding: 20px 0;
				border-bottom: solid 1px $grayL;
			}
			@include mq(sp) {
				display: block;
				padding: 15px 4%;
			}
			p {
				padding: 0 0 5px;
			}
		}
	}

	.formError {
		color: $red;
	}

	label.inputS {
		display: inline-block;
		vertical-align: top;
		@include mq(pc) {
			line-height: 52px;
			padding-right: 0.5em;
		}
		@include mq(tab) {
			line-height: 52px;
			padding-right: 0.5em;
		}
		@include mq(sp) {
			line-height: 35px;
			width: 12%;
			margin-bottom: 10px;
			&:last-of-type {
				margin-bottom: 0;
			}
			& + div.inputS {
				width: 85%;
				margin-bottom: 10px;
				&:last-of-type {
					margin-bottom: 0;
				}
			}
		}
	}
	span.afterInput {
		display: inline-block;
		vertical-align: top;
		@include mq(pc) {
			line-height: 52px;
			margin-right: 3%;
		}
		@include mq(tab) {
			line-height: 52px;
			margin-right: 3%;
		}
		@include mq(sp) {
			line-height: 35px;
			margin-right: 30%;
		}
	}
	label.radioLabel {
		@include mq(sp) {
			display: inline-block;
			margin-bottom: 10px;
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	div.selectWrap {
		display: block;
		border: solid 1px $grayL;
		overflow: hidden;
		@include mq(pc) {
			width: 400px;
		}
		@include mq(tab) {
			width: 100%;
		}
		@include mq(sp) {
			width: 100%;
			box-sizing: border-box;
			height: 35px;
		}
		&.inputS {
			display: inline-block;
			@include mq(pc) {
				width: 20%;
				margin-right: 3%;
			}
			@include mq(tab) {
				width: 30%;
				margin-right: 3%;
			}
			@include mq(sp) {
				width: 100%;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&.inputYear {
			display: inline-block;
			@include mq(pc) {
				width: 20%;
			}
			@include mq(tab) {
				width: 20%;
			}
			@include mq(sp) {
				width: 50%;
				margin-bottom: 10px;
			}
			&:last-child {
				margin-right: 0;
			}
		}
		&.inputDay {
			display: inline-block;
			@include mq(pc) {
				width: 15%;
			}
			@include mq(tab) {
				width: 15%;
			}
			@include mq(sp) {
				margin-bottom: 10px;
				width: 40%;
			}
		}
		&.multiLine {
			margin-top: 10px;
		}
		select {
			width: 110%;
			background: url('/img/common/select_btn.svg') 90% center no-repeat;
			border: 0;
			@include mq(sp) {
				width: 125%;
				height: 114.28%;
			}
		}
		input {
			background: $white;
			border: 0;
			@include mq(pc) {
				width: 100%;
			}
			@include mq(tab) {
				width: 100%;
			}
			@include mq(sp) {
				width: 114.28%;
				height: 38px;
			}
		}
	}
	textarea {
		box-sizing: border-box;
		width: 100%;
		border: solid 1px $grayL;
	}
	p.labelFrame {
		& > label {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
	}
	span.check {
		@include mq(pc) {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(tab) {
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(sp) {
			display: block;
		}
	}

	div.formBtnWrap {
		text-align: center;
		@include mq(pc) {
			padding: 30px 0;
		}
		@include mq(tab) {
			padding: 30px 2%;
		}
		@include mq(sp) {
		}
		& > div {
			display: inline-block;
			vertical-align: middle;
			@include mq(pc) {
				width: 450px;
				margin-right: 20px;
				&:last-child { margin-right: 0; }
			}
			@include mq(tab) {
				width: 38%;
				margin-right: 4%;
				&:last-child { margin-right: 0; }
			}
			@include mq(sp) {
				width: 92%;
				margin-bottom: 20px;
				&:last-child { margin-bottom: 0; }
			}
		}
		input {
			@extend .btnL;
			width: 100%;
			height: 66px;
			transform:scale(1);
			@include mq(pc) {
				font-size: 18px;
				font-size: 1.8rem;
			}
			@include mq(tab) {
				font-size: 18px;
				font-size: 1.8rem;
			}
			@include mq(sp) {
				font-size: 14px;
				font-size: 1.4rem;
			}
			&:hover {
				transition: border-radius 0.4s;
				border-radius: 10px;
			}
		}
		a {
			box-sizing: border-box;
			transition: border-radius 0.4s;
			cursor: pointer;
			@extend .btnL;
			@include mq(pc) {
				width: 100%;
				padding: 23px 0;
				font-size: 18px;
				font-size: 1.8rem;
			}
			@include mq(tab) {
				width: 100%;
				padding: 23px 0;
				font-size: 18px;
				font-size: 1.8rem;
			}
			@include mq(sp) {
				width: 100%;
				padding: 25px 0;
				font-size: 14px;
				font-size: 1.4rem;
			}
			&:hover {
				transition: border-radius 0.4s;
				border-radius: 10px;
			}
		}
		div.btnConfirm a,
		div.btnConfirm input{
			background: url('/img/common/icon_tri_right_gray.svg') 94% center no-repeat;
			background-size: 12px;
			&:hover {
				background-color: $grayLL;
			}
		}
		div.btnBack a{
			background: url('/img/common/icon_tri_left_gray.svg') 6% center no-repeat;
			background-size: 12px;
			&:hover {
				background-color: $grayLL;
			}
		}
		div.btnSend a,
		div.btnSend input{
			background: $gray url('/img/common/icon_tri_right_white.svg') 94% center no-repeat;
			background-size: 12px;
			color: $white;
			&:hover {
				background-color: $grayL;
			}
		}
		div.btnComplete a{
			background: url('/img/common/icon_tri_right_gray.svg') 94% center no-repeat;
			background-size: 12px;
			&:hover {
				background-color: $grayLL;
			}
		}
		div.btnWarn a,
		div.btnWarn input {
			color: $white;
			border-color: $red;
			background: $red url('/img/common/icon_tri_right_white.svg') 94% center no-repeat;
			background-size: 12px;
		}
	}

	// カレンダー
	div.calendarBlock {
		@extend %clearfix;
		@include mq(sp) {
			margin-bottom: 5px;
		}
		div.calendar {
			overflow: hidden;
			border: 1px solid #d2d2d2;
			line-height: 1;
			@include mq(pc) {
				float: left;
				width: 230px;
				height: 265px;
				margin: 0 10px 10px 0;
				display: inline-block;
			}
			@include mq(tab) {
				float: left;
				width: 230px;
				height: 265px;
				margin: 0 10px 10px 0;
				display: inline-block;
			}
			@include mq(sp) {
				width: 100%;
				margin: 0;
				&:nth-child(n + 2) {
					border-top: 0;
				}
				&.open {
					div.yearMonthTitle::after {
						transition: transform 0.4s;
						transform: rotate(-90deg);
					}
					div.calendarMain {
						transition: max-height 1s;
						height: auto;
						max-height: 1000px;
					}
				}
			}
		}
		div.yearMonthTitle {
			line-height: 25px;
			text-align: center;
			font-size: 15px;
			font-size: 1.5rem;
			@include mq(pc) {
				padding: 15px 0 0;
			}
			@include mq(tab) {
				padding: 15px 0 0;
			}
			@include mq(sp) {
				padding: 15px;
				cursor: pointer;
				&::after {
					content: '';
					background: url('/img/common/icon_arrow_right_gray.svg') center center no-repeat;
					background-size: 10px;
					display: inline-block;
					float: right;
					width: 21px;
					height: 21px;
					transition: transform 0.4s;
					transform: rotate(90deg);
				}
			}
		}
		div.calendarMain {
			padding: 4px;
			@include mq(sp) {
				box-sizing: border-box;
				transition: max-height 0.4s;
				padding: 0 3%;
				overflow: hidden;
				max-height: 0px;
			}
		}
		table {
			border-collapse: collapse;
			border-spacing: 0;
			border: none;
			width: 100%;
			margin: 3px auto 0px;
			@include mq(sp) {
				margin-bottom: 4px;
			}
		}
		th {
			width: auto;
			font-weight: bold;
			text-align: center;
			padding: 0 0 6px;
			background: $white;
			border: 0;
		}
		td {
			border: none;
			width: auto;
			height: auto;
			padding: 2px;
			text-align: center;
			a {
				border: solid 1px #CBCBCB;
				background: #EEEEEE;
				color: #444444;
				display: block;
				height: 100%;
				padding: 5px 0 5px;
				text-decoration: none;
				&.disabled {
					border: solid 1px #eaeaea;
					background: #f8f8f8;
					color: #c2c2c2;
				}
			}
		}
	}
	.disp_date {
		font-size: 24px;
		font-size: 2.4rem;
	}
	// /カレンダー

	div.brandSelect {
		& > ul {
			@extend %clearfix;
			& > li {
				@include mq(pc) {
					float: left;
					width: 33%;
				}
				@include mq(tab) {
					float: left;
					width: 50%;
				}
			}
		}
	}
	div.zipInput {
		@extend %clearfix;
		div.selectWrap {
			float: left;
			width: 50%;
			margin-right:1%;
		}
		a.adjustButton {
			@extend .btnL;
			float: left;
			box-sizing: border-box;
			width: 35%;
			display: block;
			line-height: 17px;
			border: 1px solid $gray;
			background: $gray;
			color: $white;
			@include mq(sp) {
				padding: 6px 0 5px;
			}
		}
	}

	ul.inputList {
		max-width: 450px;
		margin: 0 auto;
		@include mq(tab) {
			padding: 0 2%;
		}
		@include mq(sp) {
			padding: 0 4%;
		}
		& > li {
			margin-bottom: 10px;
			div.selectWrap {
				width: 100%;
			}
		}
	}

} // formWrap

div.confirmWrap {
	td > p {
		@include mq(pc) {
			font-size: 20px;
			font-size: 2.0rem;
		}
		@include mq(tab) {
			font-size: 20px;
			font-size: 2.0rem;
		}
		@include mq(sp) {
			font-size: 16px;
			font-size: 1.6rem;
		}
	}
}


// ==================================
// Shopinfo page
// ==================================
div.ecLogin {
	p.toForgot {
		text-align: center;
		padding: 10px 0 0;
	}
	div.formInputWrap {
		input.iconMail,
		input.iconLock {
			background-repeat: no-repeat;
			padding-left: 50px;
		}
		input.iconMail {
			background-image: url('/img/common/icon_mail.svg');
			background-position: 13px center;
		}
		input.iconLock {
			background-image: url('/img/common/icon_lock.svg');
			background-position: 15px center;
		}
	}
}


// ==================================
// Entry Add Input
// ==================================
div.entryaddInput {
	table.formInputTable {
		br.sp {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none; }
		}
	}
}

