@charset "UTF-8";

// ==================================
// Left Right Image
// ==================================
div.parts_leftImg,
div.parts_rightImg {
	position: relative;
	div.txtWrap {
		box-sizing: border-box;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 70px 0;
			h5,h4,p {
				width: 42.73%;
			}
		}
		@include mq(tab) {
			padding: 70px 2%;
			h5,h4,p {
				width: 42.73%;
			}
		}
		@include mq(sp) {
			padding: 20px 4%;
		}
		h5 {
			color: $gray;
			@include mq(pc) {
				margin-bottom: 18px;
			}
			@include mq(tab) {
				margin-bottom: 18px;
			}
			@include mq(sp) {
				font-weight: 300;
			}
		}
		h4 {
			font-weight: 500;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(tab) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(sp) {
				font-size: 20px;
				font-size: 2.0rem;
				margin-bottom: 10px;
				br { display: none; }
			}
		}
		p {
			line-height: 1.8;
		}
	}
	div.imgWrap {
		background: center center no-repeat;
		background-color: inherit;
		background-size: cover;
		position: relative;
		@include mq(pc) {
			position: absolute;
			width: 50%;
			top: 0;
			bottom: 0;
			margin: auto;
		}
		@include mq(tab) {
			position: absolute;
			width: 50%;
			top: 0;
			bottom: 0;
			margin: auto;
		}
		@include mq(sp) {
			padding-bottom: 81.25%;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			background-color: inherit;
			width: 35px;
			height: 35px;
			transform: rotate(45deg);
			@include mq(pc) {
				top: 50%;
				margin: -20px auto 0;
			}
			@include mq(tab) {
				top: 50%;
				margin: -20px auto 0;
			}
			@include mq(sp) {
				right: 0;
				left: 0;
				margin: 0 auto;
			}
		}
	}
}
div.parts_leftImg {
	div.txtWrap {
		@extend %clearfix;
		@include mq(pc) {
			h5,h4,p {
				float: right;
				clear: both;
			}
		}
		@include mq(tab) {
			h5,h4,p {
				float: right;
				clear: both;
			}
		}
	}
	div.imgWrap {
		left: 0;
		&:after {
			@include mq(pc) {
				right: -20px;
			}
			@include mq(tab) {
				right: -20px;
			}
			@include mq(sp) {
				bottom: -20px;
			}
		}
	}
}
div.parts_rightImg {
	&::after {
		bottom: 81.25%;
	}
	div.imgWrap {
		right: 0;
		&:after {
			@include mq(pc) {
				left: -20px;
			}
			@include mq(tab) {
				left: -20px;
			}
			@include mq(sp) {
				top: -20px;
			}
		}
	}
}

// ==================================
// Left Image 2
// ==================================
div.parts_leftImg2,
div.parts_rightImg2 {
	position: relative;
	@extend %clearfix;
	@include mq(pc) {
		width: $widthPc;
		margin: 0 auto;
		padding: 80px 0;
	}
	@include mq(tab) {
		margin: 0 auto;
		padding: 80px 2%;
	}
	@include mq(sp) {
		padding-bottom: 20px;
	}
	div.txtWrap {
		box-sizing: border-box;
		@include mq(pc) {
			margin: 0 auto;
			width: 42.73%;
		}
		@include mq(tab) {
			width: 42.73%;
		}
		@include mq(sp) {
			padding: 20px 4%;
		}
		h5 {
			color: $gray;
			@include mq(pc) {
				margin-bottom: 18px;
			}
			@include mq(tab) {
				margin-bottom: 18px;
			}
			@include mq(sp) {
				font-weight: 300;
			}
		}
		h4 {
			font-weight: 500;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(tab) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(sp) {
				font-size: 20px;
				font-size: 2.0rem;
				margin-bottom: 10px;
				br { display: none; }
			}
		}
		p {
			line-height: 1.8;
		}
	}
	div.imgWrap {
		background: center center no-repeat;
		background-color: inherit;
		background-size: cover;
		position: relative;
		@include mq(pc) {
			width: 50%;
		}
		@include mq(tab) {
			width: 50%;
		}
		@include mq(sp) {
		}
		&::before {
			content: '';
			display: block;
			padding-bottom: 100%;
		}
		&::after {
			content: '';
			display: block;
			position: absolute;
			background-color: inherit;
			width: 35px;
			height: 35px;
			transform: rotate(45deg);
			@include mq(pc) {
				top: 50%;
				margin: -20px auto 0;
			}
			@include mq(tab) {
				top: 50%;
				margin: -20px auto 0;
			}
			@include mq(sp) {
				right: 0;
				left: 0;
				margin: 0 auto;
			}
		}
	}
}
div.parts_leftImg2 {
	div.txtWrap {
		@include mq(pc) {
			float: right;
		}
		@include mq(tab) {
			float: right;
		}
	}
	div.imgWrap {
		@include mq(pc) {
			float: left;
		}
		@include mq(tab) {
			float: left;
		}
	}
}
div.parts_rightImg2 {
	div.txtWrap {
		@include mq(pc) {
			float: left;
		}
		@include mq(tab) {
			float: left;
		}
	}
	div.imgWrap {
		@include mq(pc) {
			float: right;
		}
		@include mq(tab) {
			float: right;
		}
	}
}


// ==================================
// Detail 3 column
// ==================================
div.parts_detail3col.partsWrap {
	@extend .parts_3col;
	h2 {
		font-family: $fontBase;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			font-size: 30px;
			font-size: 3.0rem;
		}
		@include mq(tab) {
			padding-right: 2%;
			padding-left: 2%;
			font-size: 30px;
			font-size: 3.0rem;
		}
		@include mq(sp) {
			padding-right: 4%;
			padding-left: 4%;
			font-size: 20px;
			font-size: 2.0rem;
		}
	}
	ul.colList > li {
		@include mq(sp) {
			background: $white;
			border: solid 1px $grayL;
			padding: 5%;
		}
		div.img::before {
			padding-bottom: 100%;
		}
		h3 {
			text-align: left;
		}
	}
}

// ==================================
// Max BackgroundImage
// ==================================
div.parts_maxBg {
	position: relative;
	overflow: hidden;
	@extend %clearfix;
	div.container {
		position: relative;
		@include mq(pc) {
			width: $widthPc;
			margin: 80px auto;
		}
		@include mq(tab) {
			margin: 80px auto;
			padding: 0 2%;
		}
		@include mq(sp) {
			padding-bottom: 20px;
		}
	}
	div.txtWrap {
		background: rgba(255,255,255,0.7);
		@include mq(pc) {
			float: right;
			margin: 0 auto;
			width: 42.73%;
			padding: 4%;
		}
		@include mq(tab) {
			float: right;
			width: 42.73%;
			padding: 4%;
		}
		@include mq(sp) {
			margin-top: 10px;
			padding: 20px 4%;
		}
		&.t_left {
			@include mq(pc) {
				float: left;
			}
			@include mq(tab) {
				float: left;
			}
		}
		h4 {
			font-weight: 500;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(tab) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(sp) {
				font-size: 20px;
				font-size: 2.0rem;
				margin-bottom: 10px;
				br { display: none; }
			}
		}
		p {
			line-height: 1.8;
		}
	}
	div.imgWrap {
		background: center center no-repeat;
		background-color: inherit;
		background-size: cover;
		@include mq(pc) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		@include mq(tab) {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		@include mq(sp) {
			&::before {
				content: '';
				display: block;
				padding-bottom: 67.18%;
			}
		}
	}
}



// ==================================
// Max Background Image 2
// ==================================
div.parts_maxBg2 {
	position: relative;
	overflow: hidden;
	div.imgWrap {
		position: relative;
		background: center center no-repeat;
		background-size: cover;
		overflow: hidden;
	}
	div.txtWrap {
		background: rgba(255,255,255,0.7);
		text-align: center;
		&.t_left {
			text-align: left;
		}
		&.t_right {
			text-align: right;
		}
		@include mq(pc) {
			box-sizing: border-box;
			width: $widthPc;
			margin: 80px auto;
			padding: 60px;
		}
		@include mq(tab) {
			margin: 80px 2%;
			padding: 4%;
		}
		@include mq(sp) {
			margin: 20px 4%;
			padding: 20px 4%;
		}
		h4 {
			font-weight: 500;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(tab) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(sp) {
				font-size: 20px;
				font-size: 2.0rem;
				margin-bottom: 10px;
				br { display: none; }
			}
		}
		p {
			line-height: 1.8;
		}
	}
}



// ==================================
// Text Only
// ==================================
div.parts_txtOnly {
	position: relative;
	overflow: hidden;
	div.container {
		position: relative;
		@include mq(pc) {
			width: $widthPc;
			margin: 80px auto;
		}
		@include mq(tab) {
			margin: 80px auto;
			padding: 0 2%;
		}
		@include mq(sp) {
			padding: 20px 4%;
		}
	}
	div.txtWrap {
		&.t_center {
			text-align: center;
		}
		&.t_right {
			text-align: right;
		}
		h4 {
			font-weight: 500;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(tab) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(sp) {
				font-size: 20px;
				font-size: 2.0rem;
				margin-bottom: 10px;
				br { display: none; }
			}
		}
		p {
			line-height: 1.8;
		}
	}
}



// ==================================
// Cart
// ==================================
div.parts_cart {
	@extend %clearfix;
	@include mq(pc) {
		width: $widthPc;
		margin: 0 auto;
		padding: 70px 0 0;
	}
	@include mq(tab) {
		margin: 0 2%;
		padding: 40px 0 0;
	}
	@include mq(sp) {
		padding-top: 4%;
	}
	div.imgWrap,
	div.cartImgWrap {
		@include mq(pc) {
			float: left;
			width: 48%;
			margin: 0 4% 80px 0;
		}
		@include mq(tab) {
			float: left;
			width: 48%;
			margin: 0 4% 60px 0;
		}
		@include mq(sp) {
			margin: 0 4% 20px;
		}
	}
	div.mainImg,
	div.cartMainImg {
		position: relative;
		width: 100%;
		&::before {
			content: '';
			display: block;
			padding-top: 100%;
		}
		.swiper-container {
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		div.swiper-slide {
			position: relative;
			div.img {
				background: center center no-repeat;
				background-size: contain;
				display: block;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
			}
			div.caption {
				background: rgba(0,0,0,0.4);
				display: block;
				position: absolute;
				right: 0;
				bottom: 0;
				left: 0;
				color: $white;
				padding: 2%;
				opacity: 0;
				@include mq(sp) {
					font-size: 12px;
					font-size: 1.2rem;
				}
			}
		}
		div.swiper-slide-active {
			div.caption {
				opacity: 1;
				transition: opacity 0.1s ease 0.5s;
			}
		}
	}
	div.subImg,
	div.cartSubImg {
		@extend %clearfix;
		div.img {
			box-sizing: border-box;
			float: left;
			width: 19%;
			margin: 10px 1.25% 0 0;
			background: center center no-repeat;
			background-size: cover;
			position: relative;
			&:hover {
				cursor: pointer;
				@extend %linkHover;
			}
			&.active::after {
				content: '';
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				border: solid 3px $grayL;
				background: none;
			}
			&:nth-child(5n) {
				margin-right: 0;
			}
			&::before {
				content: '';
				display: block;
				padding-bottom: 100%;
			}
		}
	}

	div.detailWrap,
	div.cartDetailWrap {
		@include mq(pc) {
			float: left;
			width: 48%;
			margin-bottom: 80px;
		}
		@include mq(tab) {
			float: left;
			width: 48%;
			margin-bottom: 60px;
		}
		h5 {
			color: $gray;
			font-size: 12px;
			font-size: 1.2rem;
			font-weight: 300;
			@include mq(pc) {
				margin-bottom: 15px;
			}
			@include mq(tab) {
				margin-bottom: 15px;
			}
			@include mq(sp) {
				margin: 0 4%;
			}
		}
		h4 {
			font-weight: 300;
			@include mq(pc) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(tab) {
				font-size: 26px;
				font-size: 2.6rem;
				margin-bottom: 40px;
			}
			@include mq(sp) {
				font-size: 20px;
				font-size: 2.0rem;
				margin: 0 4% 10px;
			}
		}
		div.priceWrap {
			@include mq(pc) {
				margin-bottom: 40px;
			}
			@include mq(tab) {
				margin-bottom: 40px;
			}
			@include mq(sp) {
				margin: 0 4% 20px;
			}
			p {
				display: inline-block;
				vertical-align: middle;
				@include mq(pc) {
					line-height: 26px;
					&.title {
						font-size: 16px;
						font-size: 1.6rem;
					}
					&.price {
						font-size: 26px;
						font-size: 2.6rem;
					}
				}
				@include mq(tab) {
					line-height: 26px;
					&.title {
						font-size: 16px;
						font-size: 1.6rem;
					}
					&.price {
						font-size: 26px;
						font-size: 2.6rem;
					}
				}
				@include mq(sp) {
					line-height: 15px;
					&.title {
						font-size: 12px;
						font-size: 1.2rem;
					}
					&.price {
						font-size: 15px;
						font-size: 1.5rem;
					}
				}
			}
		}
		ul.inputList {
			@extend %clearfix;
			overflow: hidden;
			border-top: solid 1px $grayL;
			border-bottom: solid 1px $grayL;
			@include mq(pc) {
				padding: 20px 0;
				margin-bottom: 20px;
			}
			@include mq(tab) {
				padding: 20px 0;
				margin-bottom: 20px;
			}
			@include mq(sp) {
				margin-bottom: 10px;
			}
			& > li {
				@extend %clearfix;
				@include mq(pc) {
					margin-bottom: 20px;
				}
				@include mq(tab) {
					margin-bottom: 20px;
				}
				@include mq(sp) {
					border-bottom: dotted 1px $grayL;
					padding: 10px 4%;
					&:last-child {
						border: 0;
					}
				}
			}
			span.title {
				display: block;
				@include mq(pc) {
					width: 120px;
					float: left;
					padding: 15px 1em 15px 0;
				}
				@include mq(tab) {
					width: 120px;
					float: left;
					padding: 15px 1em 15px 0;
				}
				@include mq(sp) {
				}
			}
			div.selectWrap {
				display: block;
				border: solid 1px $grayL;
				overflow: hidden;
				@include mq(pc) {
					float: left;
					width: 190px;
				}
				@include mq(tab) {
					width: 190px;
				}
				@include mq(sp) {
					float: left;
					box-sizing: border-box;
					width: 100%;
					height: 35px;
				}
				input {
					text-align: right;
					width: 100%;
					@include mq(sp) {
						width: 114.28%;
						height: 114.28%;
					}
				}
			}
			div.selectOne {
				@extend .selectWrap;
				border: 0;
				@include mq(pc) {
					padding: 15px 1em 15px 0;
				}
				@include mq(tab) {
					padding: 15px 1em 15px 0;
				}
			}
			select {
				width: 110%;
				background: url('/img/common/select_btn.svg') 90% center no-repeat;
				border: 0;
				@include mq(sp) {
					width: 125%;
					height: 114.28%;
				}
			}
		}
		form {
			@include mq(pc) {
				padding-bottom: 20px;
			}
			@include mq(tab) {
				padding-bottom: 20px;
			}
			@include mq(sp) {
				padding-bottom: 10px;
			}
		}
		div.submitWrap {
			@include mq(pc) {
				padding: 20px 0;
			}
			@include mq(tab) {
				padding: 20px 0;
			}
			@include mq(sp) {
				padding: 10px 0;
				text-align: center;
			}
			input {
				color: $white;
				border: $red;
				background: $red url('/img/common/icon_cart_white.svg') 25px center no-repeat;
				@include mq(pc) {
					padding: 15px 25px 15px 60px;
				}
				@include mq(tab) {
					padding: 15px 25px 15px 60px;
				}
				@include mq(sp) {
					box-sizing: border-box;
					width: 92%;
					transform: scale(1);
					padding: 15px 0;
					background-position: 50px center;
				}
			}
		}
		div.submitErr {
			color: $red;
			background: $redL;
			@include mq(pc) {
				padding: 15px;
				margin-bottom: 20px;
			}
			@include mq(tab) {
				padding: 15px;
				margin-bottom: 20px;
			}
			@include mq(sp) {
				box-sizing: border-box;
				width: 91.42%;
				margin: 0 auto 10px;
				padding: 5px;
			}
		}
		div.itemSpecWrap {
			&.open {
				h5::after {
					transition: transform 0.2s;
					transform: rotate(-90deg);
				}
				ul.itemSpec {
					@include mq(sp) {
						transition: max-height 0.4s;
						max-height: 1000px;
					}
				}
			}
			h5 {
				color: $fontColor;
				font-size: 16px;
				font-size: 1.6rem;
				@include mq(sp) {
					font-weight: 400;
					border-top: solid 1px $grayL;
					border-bottom: solid 1px $grayL;
					margin: 0;
					padding: 20px 4%;
					&::after {
						transition: transform 0.2s;
						content: '';
						background: url(/img/common/icon_tri_right.svg) center center no-repeat;
						background-size: contain;
						display: inline-block;
						margin-top: 5px;
						float: right;
						width: 15px;
						height: 15px;
						transform: rotate(90deg);
					}
				}
			}
			ul.itemSpec {
				@include mq(pc) {
					border-top: dotted 1px $grayL;
				}
				@include mq(tab) {
					border-top: dotted 1px $grayL;
				}
				@include mq(sp) {
					transition: max-height 0.4s;
					max-height: 0;
					overflow: hidden;
				}
				& > li {
					border-bottom: dotted 1px $grayL;
					@extend %clearfix;
					@include mq(pc) {
						padding: 10px 0;
						div.title {
							float: left;
							font-weight: 500;
							width: 13%;
							padding-right: 5%;
						}
						div.spec {
							float: left;
							width: 82%;
						}
					}
					@include mq(tab) {
						padding: 10px 0;
						div.title {
							float: left;
							font-weight: 500;
							width: 18%;
							padding-right: 5%;
						}
						div.spec {
							float: left;
							width: 77%;
						}
					}
					@include mq(sp) {
						div.title {
							padding: 10px 4%;
							background: $grayLL;
						}
						div.spec {
							padding: 10px 4%;
							font-size: 12px;
							font-size: 1.2rem;
						}
					}
				}
			}
		}
	}
	div.pageBack {
		padding: 40px 0;
		position: relative;
		a {
			@extend .btnL;
			display: block;
			font-family: $fontBase;
			margin: 0 auto;
			background: url('/img/common/icon_tri_left_gray.svg') 4% center no-repeat;
			background-size: 12px;
			box-sizing: border-box;
			transition: border-radius 0.4s;
			padding: 20px 0;
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none; }
			@include mq(sp) {
				width: 92%;
			}
		}
	}
}



// ==================================
// Parts Same Brand & Checked Item
// ==================================
div.parts_samebrand.partsWrap,
div.parts_checkeditem.partsWrap {
	@extend %clearfix;
	width: 100%;
	max-height: 500px;
	overflow: hidden;
	position: relative;
	@include mq(pc) {
		padding: 40px 0;
	}
	@include mq(tab) {
		padding: 30px 0 40px;
	}
	@include mq(sp) {
		padding: 0 0 40px;
		border-top: solid 1px $gray;
		border-: solid 1px $gray;
	}
	h2 {
		text-align: left;
		font-family: $fontBase;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding-top: 0;
			font-size: 20px;
			font-size: 2.0rem;
			font-weight: bold;
		}
		@include mq(tab) {
			margin: 0 2%;
			padding-top: 0;
			font-size: 20px;
			font-size: 2.0rem;
			font-weight: bold;
		}
		@include mq(sp) {
			margin: 0 4%;
			padding: 20px 0;
			font-size: 14px;
			font-size: 1.4rem;
		}
	}
	.swiper-container {
		@include mq(pc) {
			width: $widthPc;
		}
		@include mq(tab) {
			width: 96%;
		}
		@include mq(sp) {
			width: 92%;
		}
	}

	.swiper-slide {
		a {
			text-decoration: none;
			color: $fontColor;
			&:hover {
				opacity: 1;
			}
		}
		div.img {
			border: solid 1px $grayL;
			background: center center no-repeat;
			background-size: cover;
			display: block;
			position: relative;
			&:before {
				content: '';
				display: block;
				padding-top: 100%;
			}
		}
		div.detail {
			h3.hoverT {
				font-size: 18px;
				font-size: 1.8rem;
				letter-spacing: 3px;
				text-align: center;
			}
			div.arrowBottom {
				width: 10px;
				height: 10px;
				margin: 0 auto;
				border-bottom: solid 1px $white;
				border-right: solid 1px $white;
				transform: rotate(45deg);
			}
		}
		div.txtWrap {
			@extend %clearfix;
			padding: 10px 0;
			@include mq(sp) {
				padding: 0;
			}
			p.brand {
				color: $gray;
				font-size: 12px;
				font-size: 1.2rem;
				font-weight: bold;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				@include mq(sp) {
					font-size: 10px;
					font-size: 1.0rem;
				}
			}
			p.name {
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: bold;
				overflow: hidden;
				height: 52px;
				padding-bottom: 3px;
				@include mq(sp) {
					font-size: 12px;
					font-size: 1.2rem;
					height: 37px;
				}
			}
			p.specprice {
				@include mq(sp) {
					font-size: 11px;
					font-size: 1.1rem;
				}
			}
			span.spec {
				margin-right: 3px;
			}
		}
	}
	.swBtnNext,
	.swBtnPrev {
		margin-top: -64px;
		@include mq(sp) {
			display: block;
			position: relative;
			margin: 15px 0 0;
			background: $white;
			width: 70px;
			height: 30px;
			position: relative;
			border: solid 1px $gray;
			&::after {
				content: '';
				display: block;
				position: absolute;
				border-top: solid 1px $gray;
				border-right: solid 1px $gray;
				width: 10px;
				height: 10px;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				margin: auto;
			}
		}
	}
	.swBtnNext {
		@include mq(sp) {
			float: right;
			border-right: 0;
			&::after {
				transform: rotate(45deg);
			}
		}
	}
	.swBtnPrev {
		@include mq(sp) {
			float: left;
			border-left: 0;
			&::after {
				transform: rotate(-135deg);
			}
		}
	}
}
