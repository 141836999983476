@charset "UTF-8";
/* #overview
Parts / Main
*/

// ==================================
// Parts
// ==================================

// フォント
// ---------------------------------
/* #styleguide
フォントファミリー
```
<p class="ffHalant">Halant</p>
``` */
.ffHalant {
	font-family: Halant, $fontSerif;
}

a {
	color: $fontColor;
}

input,select {
	box-sizing: border-box;
	font-family: $fontBase;
	border: 0;
	margin: 0;
	background: none transparent;
	vertical-align: middle;
	font-size: 14px;
	font-size: 1.4rem;
	color: inherit;
	border-radius: 0;
	@include mq(pc) {
		padding: 15px;
	}
	@include mq(tab) {
		padding: 15px;
	}
	@include mq(sp) {
		padding: 5px 10px;
		font-size: 16px;
		font-size: 1.6rem;
		transform: scale(0.875);
		transform-origin: left top;
	}
}
input[type="text"],
input[type="password"] {
	border: solid 1px $grayL;
	&::placeholder {
		color: $grayL;
	}
}
input[type="submit"],
input[type="button"] {
	border: solid 1px $gray;
	color: $white;
	background: $gray;
	letter-spacing: 3px;
	appearance: none;
	&:hover {
		@extend %linkHover;
		cursor: pointer;
	}
}
select {
	border: solid 1px $grayL;
	border-radius: 0;
	background: $white;
	appearance: none;
}
select::-ms-expand {
	display: none;
}
option {
	&.placeholder {
		display: none;
	}
}
/* #styleguide
チェックボックス・ラジオ

labelで囲い、inputの後ろにspanを置く。
input直後のspanがcheckboxになるため、記述順に注意。
```
<label><input type="checkbox"><span></span>Pink Dolphin Diamond</label>
<label><input type="radio"><span></span>Pink Dolphin Diamond</label>
``` */
label {
	cursor: pointer;
	input[type="radio"],
	input[type="checkbox"] {
		display: none;
	}
	input[type="radio"] {
		& + span {
			display: inline-block;
			position: relative;
			vertical-align: text-bottom;
			border: 1px solid $grayL;
			width: 16px;
			height: 16px;
			margin-right: 10px;
			background: $white;
			border-radius: 100%;
		}
		&:checked + span::after {
			position: absolute;
			content: '';
			top: 50%;
			right: 0;
			left: 0;
			width: 10px;
			height: 10px;
			margin: -5px auto 0;
			background: $blue;
			border-radius: 100%;
		}
	}
	input[type="checkbox"] {
		& + span {
			display: inline-block;
			position: relative;
			vertical-align: text-bottom;
			border: 1px solid $grayL;
			width: 16px;
			height: 16px;
			margin-right: 10px;
			background: $white;
		}
		&:checked + span::after {
			position: absolute;
			content: '';
			top: 0;
			left: 3px;
			width: 13px;
			height: 5px;
			border-left: 2px solid $blue;
			border-bottom: 2px solid $blue;
			transform: rotate(-45deg);
		}
	}
}
span.check {
	line-height: 28px;
	label {
		position: relative;
		display: inline-block;
		cursor: pointer;
		padding-left: 26px;
		&::before {
			position: absolute;
			content: '';
			top: 50%;
			left: 0;
			width: 16px;
			height: 16px;
			margin-top: -9px;
			border: 1px solid $grayL;
		}
	}
	input[type="radio"] {
		display: none;
		& + label::before {
			background: $white;
			border-radius: 100%;
		}
		&:checked + label::after {
			position: absolute;
			content: '';
			top: 50%;
			left: 4px;
			width: 10px;
			height: 10px;
			margin-top: -5px;
			background: $blue;
			border-radius: 100%;
		}
	}
	input[type="checkbox"] {
		display: none;
		& + label::before {
			background: $white;
		}
		&:checked + label::after {
			position: absolute;
			content: '';
			top: 8px;
			left: 3px;
			width: 13px;
			height: 5px;
			border-left: 2px solid $blue;
			border-bottom: 2px solid $blue;
			transform: rotate(-45deg);
		}
	}
}






/* #styleguide
アイコン
```
<p class="blank">別ウィンドウで開く</p>
``` */
.blank::after {
	content: '';
	margin-left: 0.25em;
	margin-right: 0.25em;
	display: inline-block;
	height: 0.8em;
	width: 1em;
	overflow: hidden;
	background: url('/img/common/icon_blank.svg') center bottom no-repeat;
	background-size: contain;
}


// ホバーギミック
// ---------------------------------
/* #styleguide
Hover Title and Caption

usase:
wrapperに.hoverTC、タイトルに.hoverT、キャプションに.hoverCを付与。
要素に関しては問わない。
親要素にpositionを指定。
```
<div style="position: relative; width: 200px; height: 200px; border: solid 1px #555;">
<div class="hoverTC">
	<h3 class="hoverT">商品名がここに入ります</h3>
	<div class="txt hoverC">
		<p class="spec">Pt950/K18YG</p>
		<p class="price">&yen;103,680〜</p>
	</div>
</div>
</div>
``` */
.hoverTC {
	background: rgba(0,0,0,0.6);
	opacity: 0;
	transition: 0.6s ease;
	position: relative;
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	a:hover &,
	&:hover {
		transition: 0.6s ease;
		opacity: 1;
		.hoverT, .hoverC {
			transform: translate(0,0);
			transition: 0.6s ease;
		}
	}
	.hoverT, .hoverC {
		position: absolute;
		text-align: center;
		right: 8%;
		left: 8%;
		margin: 0 auto;
		transition: 0.6s ease;
		color: $white;
	}
	.hoverT {
		bottom: 53%;
		font-size: 14px;
		font-size: 1.4rem;
		padding-bottom: 5px;
		border-bottom: solid 1px $white;
		transform: translate(0,-100%);
	}
	.hoverC {
		top: 52%;
		transform: translate(0,50%);
		@extend .ffHalant;
		font-size: 16px;
		font-size: 1.6rem;
		font-weight: 100;
	}
}

// コンテナ
// ---------------------------------
.container {
	@extend %clearfix;
	@include mq(pc) {
		width: $widthPc;
		margin: 0 auto;
	}
	@include mq(tab) {
		margin: 0 2%;
	}
	@include mq(sp) {
		margin: 0 4%;
	}
}


// ボタン
// ---------------------------------
/* #styleguide
ボタン
```
<a class="btnS" href="#">ボタンS</a>
<div><a class="btnL" href="#">
	<span class="top">Button L</span>
	<span class="bottom">ボタンL</span>
</a></div>
``` */
.btnS {
	@extend .ffHalant;
	display: inline-block;
	border: solid 1px $gray;
	padding: 3px 15px;
	letter-spacing: 1px;
	color: $fontColor;
	text-decoration: none;
}
.btnL {
	display: inline-block;
	padding: 1em;
	border: solid 1px $gray;
	letter-spacing: 1px;
	color: $fontColor;
	text-decoration: none;
	line-height: 1;
	text-align: center;
	span {
		display: block;
		&.top {
			color: $fontColor;
			@extend .ffHalant;
			@include mq(pc) {
				font-size: 20px;
				font-size: 2.0rem;
			}
			@include mq(tab) {
				font-size: 20px;
				font-size: 2.0rem;
			}
			@include mq(sp) {
			}
		}
		&.bottom {
			color: $gray;
			@include mq(pc) {
				font-size: 14px;
				font-size: 1.4rem;
			}
			@include mq(tab) {
				font-size: 14px;
				font-size: 1.4rem;
			}
			@include mq(sp) {
				font-size: 12px;
				font-size: 1.2rem;
			}
		}
	}
}
.viewmore {
	@extend .btnL;
	display: block;
	margin: 0 auto;
	background: url('/img/common/icon_tri_right_gray.svg') 95% center no-repeat;
	box-sizing: border-box;
	transition: border-radius 0.4s;
	@include mq(pc) {
		width: 455px;
	}
	@include mq(tab) {
		width: 455px;
	}
	@include mq(sp) {
		width: 92%;
	}
	&:hover {
		transition: border-radius 0.4s;
		border-radius: 10px;
		background-color: $grayLL;
	}
}


// 画像/在庫無し
// ---------------------------------
.noimg {
	background-image: url('/img/common/icon_noimg.svg') !important;
	background-color: $grayLL !important;
	background-size: 70px !important;
}
.blockNoStock {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(255,255,255,0.6);
	p {
		color: $fontColor;
		border-top: solid 1px $fontColor;
		border-bottom: solid 1px $fontColor;
		width: 80%;
		text-align: center;
		margin: 40% auto 0;
		@extend .ffHalant;
		@include mq(pc) {
			font-size: 22px;
			font-size: 2.2rem;
		}
		@include mq(tab) {
			font-size: 22px;
			font-size: 2.2rem;
		}
	}
}


// google map
// ---------------------------------
.gmap {
	cursor: pointer;
	iframe {
		pointer-events: none;
		&.moving {
			pointer-events: auto;
		}
	}
}

// ==================================
// wrapper
// ==================================
div#wrapper {
	position: relative;
	@include mq(pc) {
		padding-top: 147px;
	}
	@include mq(tabL) {
		padding-top: 140px;
	}
	@include mq(tabS) {
		padding-top: 48px;
	}
}

div#contentHome {
	& > div.partsWrap:last-child {
		padding-bottom: 100px;
	}
}



// ==================================
// header
// ==================================
div#header {
	box-sizing: border-box;
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	width: 100%;
	background-color: $white;
	z-index: 100;
	@include mq(pc) {
		height: 147px;
	}
	@include mq(tabL) {
		height: 140px;
	}
}
// headerTop
// ---------------------------------
div#headerTop {
	background-color: $white;
	@include mq(pc) {
		padding: 30px 0;
	}
	@include mq(tabL) {
		padding: 30px 0;
	}
	@include mq(tabS) {
		position: relative;
		padding: 15px 0;
		box-shadow: 0 1px 1px rgba(0,0,0,0.1);
		z-index: 100;
	}
	div.container {
		position: relative;
	}
}
div#bnrBridal {
	position: absolute;
	top: 0;
	left: 0;
	@include mq(tabS) {
		display: none;
	}
}
h1#headerLogo {
	text-align: center;
	@include mq(pc) {
		height: 35px;
	}
	@include mq(tabL) {
		height: 35px;
	}
	@include mq(tabS) {
		height: 18px;
	}
	img {
		width: auto;
		height: 100%;
	}
}
ul#headerSubMenu {
	@extend %clearfix;
	position: absolute;
	top: 0;
	right: 0;
	li {
		float: left;
		margin-right: 25px;
		@include mq(pc) {
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(tabL) {
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(tabS) {
			&:nth-last-child(2) {
				margin-right: 0;
			}
		}
	}
	a.icon, div.icon {
		display: block;
		overflow: hidden;
		text-indent: -999px;
		@include mq(pc) {
			width: 30px;
			height: 30px;
		}
		@include mq(tabL) {
			width: 30px;
			height: 30px;
		}
		@include mq(tabS) {
			width: 20px;
			height: 20px;
		}
	}
}
#btnHeaderSearch div {
	background: url('/img/common/icon_search.svg') center center no-repeat;
	background-size: contain;
	&:hover {
		@extend %linkHover;
		cursor: pointer;
	}
}
#btnHeaderCart a {
	background: url('/img/common/icon_cart.svg') center center no-repeat;
	background-size: contain;
}
@include mq(tabS) {
	#btnHeaderLogin {
		display: none;
	}
}
div#headerMenuHumb {
	@include mq(pc) {
		display: none;
	}
	@include mq(tabL) {
		display: none;
	}
	@include mq(tabS) {
		position: absolute;
		top: 0;
		left: 0;
		width: 20px;
		height: 20px;
		background: url('/img/common/icon_humb.svg') center center no-repeat;
		background-size: contain;
		cursor: pointer;
		&.open {
		background-image: url('/img/common/icon_cross.svg');
		}
	}
}

// headerSpSearch
// ---------------------------------
div#headerSpSearch {
	@include mq(pc) {
		display: none;
	}
	@include mq(tabL) {
		display: none;
	}
}

// headerBottom
// ---------------------------------
div#headerBottom {
	background-color: $white;
	box-shadow: 0 3px 3px rgba(0,0,0,0.2);
	@include mq(pc) {
		height: 52px;
	}
	@include mq(tabL) {
		height: 45px;
	}
}
ul#headerMenu {
	@include mq(pc) {
		text-align: center;
	}
	@include mq(tabL) {
		text-align: center;
	}
	@include mq(tabS) {
		margin: 0;
		position: absolute;
		top: -999px;
		left: 0;
		right: 0;
		transition: top 0.5s;
		box-shadow: 0 2px 2px rgba(0,0,0,0.2);
		text-size-adjust: 100%;
		&.open {
			transition: top 0.5s;
			top: 100%;
		}
		&.sizeover {
			overflow-y: scroll;
			overflow-X: hidden;
		}
	}
	& > li {
		@extend .ffHalant;
		@include mq(pc) {
			font-weight: 300;
			display: inline-block;
			font-size: 20px;
			font-size: 2.0rem;
			margin-right: 30px;
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(tabL) {
			font-weight: 300;
			display: inline-block;
			font-size: 16px;
			font-size: 1.6rem;
			margin-right: 20px;
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(tabS) {
			font-family: $fontBase;
			text-align: left;
			border-bottom: solid 1px $black;
			background: $grayLL;
		}
		&.hasChild {
			.parent {
				padding-right: 35px;
				padding-left: 10px;
				position: relative;
				z-index: 0;
				&:hover {
					@extend %linkHover;
					cursor: pointer;
				}
				&::after {
					@include mq(pc) {
						position: absolute;
						display: block;
						top: 0;
						right: 10px;
						bottom: 0;
						transition: 0.1s;
						content: url('/img/common/icon_haschild.svg');
					}
					@include mq(tabL) {
						position: absolute;
						display: block;
						top: 0;
						right: 10px;
						bottom: 0;
						transition: 0.1s;
						content: url('/img/common/icon_haschild.svg');
					}
					@include mq(tabS) {
						position: absolute;
						display: block;
						top: 20px;
						right: 10px;
						content: '';
						background: url('/img/common/icon_tri_right.svg') center right no-repeat;
						background-size: 50%;
						width: 20px;
						height: 20px;
					}
				}
				@include mq(tabS) {
					padding: 20px 4%;
				}
			}
			&.open {
				@include mq(pc) {
					.parent {
						.en { opacity: 0; }
						.jp { opacity: 1; }
					}
					.parent::after {
						transition: 0.1s;
						transform: rotate(45deg) translateY(-5px);
					}
					div.childWrap {
						transition: top 0.5s;
						top: 100%;
					}
				}
				@include mq(tabL) {
					.parent {
						.en { opacity: 0; }
						.jp { opacity: 1; }
					}
					.parent::after {
						transition: 0.1s;
						transform: rotate(45deg) translateY(-5px);
					}
					div.childWrap {
						transition: top 0.5s;
						top: 100%;
					}
				}
				@include mq(tabS) {
					div.childWrap {
						transition: left 0.2s,right 0.2s;
						left: 0;
						right: 0;
					}
				}
			}
		}
		a {
			color: $fontColor;
			text-decoration: none;
			@include mq(tabS) {
				display: block;
				padding: 20px 4%;
			}
		}
		& > a, & > .parent {
			@include mq(pc) {
				padding-bottom: 20px;
				position: relative;
				&:hover {
					.en { opacity: 0; }
					.jp { opacity: 1; }
				}
			}
			@include mq(tabL) {
				padding-bottom: 20px;
				position: relative;
				&:hover {
					.en { opacity: 0 !important; }
					.jp { opacity: 1; }
				}
			}
			.en {
				@include mq(pc) {
					transition: opacity 0.4s;
				}
				@include mq(tabL) {
					transition: opacity 0.4s;
				}
			}
			.jp {
				@include mq(pc) {
					font-size: 14px;
					font-size: 1.4rem;
					text-align: center;
					letter-spacing: 0;
					line-height: 32px;
					display: block;
					white-space: nowrap;
					position: absolute;
					top: 0;
					right: 35px;
					bottom: 0;
					left: 0;
					opacity: 0;
					transition: opacity 0.4s;
				}
				@include mq(tabL) {
					font-size: 14px;
					font-size: 1.4rem;
					text-align: center;
					letter-spacing: 0;
					line-height: 25px;
					display: block;
					white-space: nowrap;
					position: absolute;
					top: 0;
					right: 35px;
					bottom: 0;
					left: 0;
					opacity: 0;
					transition: opacity 0.4s;
				}
				@include mq(tabS) {
					margin-left: 1em;
					font-size: 13px;
					font-size: 1.3rem;
				}
			}
		}
		&.login {
			@include mq(pc) {
				display: none;
			}
			@include mq(tabL) {
				display: none;
			}
			@include mq(tabS) {
				background-color: $grayL;
			}
		}
	}
	div.childWrap {
		position: absolute;
		top: 0;
		@include mq(pc) {
			transition: top 0.5s;
			z-index: -1;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
			left: 0;
			right: 0;
			background: $grayLL;
		}
		@include mq(tabL) {
			transition: top 0.5s;
			z-index: -1;
			box-shadow: 0 2px 2px rgba(0,0,0,0.2);
			left: 0;
			right: 0;
			background: $grayLL;
		}
		@include mq(tabS) {
			transition: left 0.2s,right 0.2s;
			z-index: 50;
			overflow-y: auto;
			left: 100%;
			right: -100%;
			bottom: 0;
		}
	}
	ul.child {
		font-family: $fontBase;
		font-size: 1.4px;
		font-size: 1.4rem;
		text-align: center;
		@include mq(pc) {
			padding-top: 20px;
		}
		@include mq(tabL) {
			padding-top: 20px;
		}
		@include mq(tabS) {
			z-index: 200;
			margin: 0 0 0 6%;
			box-shadow: -3px 0 3px rgba(0,0,0,0.2);
			background: $grayLL;
			min-height: 100%;
		}
		& > li {
			a {
				display: block;
			}
			@include mq(pc) {
				padding: 0 0 20px;
				display: inline-block;
				margin-right: 30px;
				&:last-child {
					margin-right: 0;
				}
			}
			@include mq(tabL) {
				padding: 0 0 20px;
				display: inline-block;
				margin: 0 15px;
			}
			@include mq(tabS) {
				text-align: left;
				border-bottom: solid 1px $black;
				a {
					display: block;
					padding: 20px 4%;
				}
			}
		}
	}
}


// ==================================
// Search Input
// ==================================
div.searchInput {
	background: $grayLL;
	@include mq(pc) {
		width: $widthPc;
		margin: 30px auto;
		padding: 20px 0;
	}
	@include mq(tab) {
		margin: 30px auto;
		padding: 20px 0;
	}
	@include mq(sp) {
		padding: 10px 0;
		span.iosText, span.iosSubmit {
			display: inline-block;
			overflow: hidden;
		}
		span.iosText {
			width: 78%;
		}
		span.iosSubmit {
			width: 18%;
		}
	}
	div.searchInputWrap {
		text-align: center;
	}
	input {
		padding: 10px;
	}
	input[type="text"] {
		@include mq(pc) {
			width: 50%;
			background: $white url('/img/common/icon_search_gray.svg') 15px center no-repeat;
			border: solid 1px $gray;
			padding-left: 50px;
		}
		@include mq(tab) {
			width: 75%;
			background: $white url('/img/common/icon_search_gray.svg') 15px center no-repeat;
			padding-left: 50px;
		}
		@include mq(sp) {
			width: 114.28%;
			background: $white;
		}
	}
	input[type="submit"] {
		@include mq(pc) {
			width: 15%;
			margin-left: 1%;
		}
		@include mq(tab) {
			width: 15%;
			margin-left: 1%;
		}
		@include mq(sp) {
			width: 114.28%;
			text-indent: -999px;
			background: $gray url('/img/common/icon_search_white.svg') center center no-repeat;
			background-size: 30px;
		}
	}
}





// ==================================
// modal window
// ==================================
// search
// ---------------------------------
#modalSearchWrap {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	background: rgba(0,0,0,0.3);
	z-index: 1000;
	height: 0;
	overflow: hidden;
	opacity: 0;
	&.open {
		animation: show 0.3s;
		height: auto;
		opacity: 1;
	}
	&.close {
		animation: hide 0.3s;
	}
}
#modalSearch {
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	box-sizing: border-box;
	padding: 40px 25px;
	background: $white;
	box-shadow: 0 0 15px rgba(0,0,0,0.5);
	margin: 60px auto 0;
	transition: margin-top 0.3s;
	.open & {
		transition: margin-top 0.3s;
	}
	@include mq(pc) {
		width: 860px;
		font-size: 24px;
		font-size: 2.4rem;
		.open & {
			margin-top: 100px;
		}
	}
	@include mq(tab) {
		width: 95%;
		font-size: 24px;
		font-size: 2.4rem;
		.open & {
			margin-top: 100px;
		}
	}
	@include mq(sp) {
		font-size: 15px;
		font-size: 1.5rem;
		.open & {
			margin-top: 70px;
		}
	}
	h2 {
		border-bottom: 1px solid $black;
		letter-spacing: 0.2px;
		@include mq(pc) {
			margin-bottom: 20px;
		}
		@include mq(tab) {
			margin-bottom: 20px;
		}
		@include mq(sp) {
			margin-bottom: 10px;
		}
		span.jp {
			font-weight: 500;
			margin-left: 1em;
			@include mq(pc) {
				font-size: 14px;
				font-size: 1.4rem;
			}
			@include mq(tab) {
				font-size: 14px;
				font-size: 1.4rem;
			}
			@include mq(sp) {
				font-size: 12px;
				font-size: 1.2rem;
			}
		}
	}
	div.searchKeyword {
		@include mq(pc) {
			padding-bottom: 40px;
		}
		@include mq(tab) {
			padding-bottom: 40px;
		}
		@include mq(sp) {
			padding-bottom: 30px;
			span.iosText, span.iosSubmit {
				display: inline-block;
				overflow: hidden;
			}
			span.iosText {
				width: 80%;
			}
			span.iosSubmit {
				width: 20%;
			}
		}
		input[type="text"] {
			@include mq(pc) {
				width: 75%;
				background: url('/img/common/icon_search_gray.svg') 15px center no-repeat;
				padding-left: 50px;
			}
			@include mq(tab) {
				width: 75%;
				background: url('/img/common/icon_search_gray.svg') 15px center no-repeat;
				padding-left: 50px;
			}
			@include mq(sp) {
				width: 114.28%;
			}
		}
		input[type="submit"] {
			@include mq(pc) {
				width: 24%;
				margin-left: 1%;
			}
			@include mq(tab) {
				width: 24%;
				margin-left: 1%;
			}
			@include mq(sp) {
				width: 114.28%;
				text-indent: -999px;
				background: $gray url('/img/common/icon_search_white.svg') center center no-repeat;
				background-size: 20px;
			}
		}
	}
	ul.searchList {
		@extend %clearfix;
		& > li {
			@include mq(pc) {
				float: left;
				width: 48%;
				margin: 0 4% 0 0;
				padding-bottom: 40px;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				float: left;
				width: 48%;
				margin: 0 4% 0 0;
				padding-bottom: 40px;
				&:nth-child(2n) {
					margin-right: 0;
				}
			}
			@include mq(sp) {
				padding-bottom: 30px;
			}
		}
		div.selectWrap {
			overflow: hidden;
			border: solid 1px $grayL;
			@include mq(sp) {
				height: 35px;
			}
		}
		select {
			width: 110%;
			background: url('/img/common/select_btn.svg') 90% center no-repeat;
			border: 0;
			@include mq(sp) {
				width: 125%;
				height: 114.28%;
			}
		}
	}
	ul.btnList {
		text-align: center;
		& > li {
			display: inline-block;
			margin-right: 1%;
			&:last-child {
				margin-right: 0;
			}
			@include mq(pc) {
				width: 25%;
			}
			@include mq(tab) {
				width: 25%;
			}
			@include mq(sp) {
				width: 48%;
			}
			input {
				box-sizing: border-box;
				width: 100%;
				@include mq(pc) {
					font-size: 18px;
					font-size: 1.8rem;
					padding: 20px 1rem 20px 0;
				}
				@include mq(tab) {
					font-size: 18px;
					font-size: 1.8rem;
					font-size: 18px;
					font-size: 1.8rem;
					padding: 20px 1rem 20px 0;
				}
				@include mq(sp) {
					padding: 10px 1rem 10px 0;
				}
				&.btnWhite {
					background: $white url('/img/common/icon_tri_right_gray.svg') 90% center no-repeat;
					background-size: 0.6em;
					color: $gray;
				}
				&.btnGray {
					background: $gray url('/img/common/icon_tri_right_white.svg') 90% center no-repeat;
					background-size: 0.6em;
				}
			}
		}
	}
}
div#modalSearchClose {
	position: absolute;
	color: $white;
	font-size: 30px;
	top: 0;
	right: 0;
	background: $grayL;
	text-align: center;
	letter-spacing: 0;
	&:hover {
		@extend %linkHover;
		cursor: pointer;
	}
	@include mq(pc) {
		width: 45px;
		height: 45px;
		line-height: 45px;
	}
	@include mq(tab) {
		width: 45px;
		height: 45px;
		line-height: 45px;
	}
	@include mq(sp) {
		width: 40px;
		height: 40px;
		line-height: 40px;
	}
}



// ==================================
// footer
// ==================================
#toTop {
	a {
		display: block;
		position: relative;
		@extend .ffHalant;
		color: $fontColor;
		font-size: 18px;
		font-size: 1.8rem;
		text-align: center;
		text-decoration: none;
		padding: 10px 0;
		border-top: solid 1px $grayL;
		background: $white;
		&::before {
			content: '';
			display: block;
			position: absolute;
			right: 0;
			left: 0;
			margin: 0 auto;
			border-top: solid 1px $grayL;
			border-left: solid 1px $grayL;
			background: $white;
			transform: rotate(45deg);
			@include mq(pc) {
				width: 14.14px;
				height: 14.14px;
				top: -9px;
			}
			@include mq(tab) {
				width: 14.14px;
				height: 14.14px;
				top: -9px;
			}
			@include mq(sp) {
				width: 25.45px;
				height: 25.45px;
				top: -14px;
			}
		}
	}
}

div#footerTop {
	background: $grayLL;
}
ul#footerListParent {
	@extend %clearfix;
	margin: 0 auto;
	@include mq(pc) {
		width: $widthPc;
		padding: 45px 0;
	}
	@include mq(tab) {
		padding: 45px 2% 20px;
		display: flex;
		flex-flow: column wrap;
		height: 500px;
	}
	@include mq(sp) {
		border-top: solid 1px $gray;
	}
	& > li {
		@include mq(pc) {
			float: left;
			width: 15%;
			margin-right: 2%;
			&:last-child {
				margin-right: 0;
			}
			&.col2 {
				width: 32%;
			}
		}
		@include mq(tab) {
			margin-bottom: 20px;
			margin-right: 2%;
			&:last-child {
				margin-right: 0;
			}
		}
		@include mq(sp) {
			&.open {
				h2::after {
					transition: transform 0.2s;
					transform: rotate(-90deg);
				}
				ul.footerListChild {
					max-height: 3000px;
					transition: max-height 0.2s;
				}
			}
			&.col2 {
				display: none;
			}
		}
	}
	h2 {
		font-size: 16px;
		font-size: 1.6rem;
		font-weight: 500;
		border-bottom: solid 1px $fontColor;
		@include mq(pc) {
			margin-bottom: 15px;
			padding-bottom: 0.2em;
		}
		@include mq(tab) {
			margin-bottom: 15px;
			padding-bottom: 0.2em;
		}
		@include mq(sp) {
			padding: 20px 4%;
			&::after {
				transition: transform 0.2s;
				content: '';
				background: url('/img/common/icon_tri_right.svg') center center no-repeat;
				background-size: contain;
				transform: rotate(90deg);
				display: inline-block;
				margin-top: 5px;
				float: right;
				width: 15px;
				height: 15px;
			}
		}
	}
	ul.footerListChild {
		@include mq(sp) {
			max-height: 0;
			overflow: hidden;
			transition: max-height 0.2s;
		}
		& > li {
			color: $grayD;
			@include mq(pc) {
				margin-bottom: 10px;
				font-size: 12px;
				font-size: 1.2rem;
			}
			@include mq(tab) {
				margin-bottom: 10px;
				font-size: 12px;
				font-size: 1.2rem;
			}
			@include mq(sp) {
				border-bottom: dotted 1px $gray;
				&:last-child {
					border-bottom: solid 1px $gray;
				}
				a, p {
					display: block;
					padding: 20px 4%;
				}
				@include mq(sp) {
					a::after {
						content: '';
						display: inline-block;
						margin: 7px 6px 0 0;
						float: right;
						width: 7px;
						height: 7px;
						border-top: solid 1px $gray;
						border-right: solid 1px $gray;
						transform: rotate(45deg);
					}
				}
			}
			a {
				color: $grayD;
				text-decoration: none;
			}
		}
	}
}
div#footerCalender {
	@extend %clearfix;
	div.calenderWrap {
		box-sizing: border-box;
		width: 49%;
		float: left;
		margin-right: 2%;
		padding: 10px 5px 5px;
		border: solid 1px $grayL;
		&:nth-child(2n) {
			margin-right: 0;
		}
	}
	h3 {
		text-align: center;
		font-weight: 500;
		margin-bottom: 5px;
	}
	table.calender {
		width: 100%;
		font-size: 11px;
		font-size: 1.1rem;
		text-align: center;
		tr {
			border-bottom: dotted 1px $grayL;
			&:last-child {
				border: none;
			}
		}
		td {
			padding: 5px;
			&.holiday {
				color: $red;
			}
		}
	}
	p.calenderComment {
		padding: 5px 0;
		font-size: 11px;
		font-size: 1.1rem;
		clear: both;
		span.attention {
			color: $red;
		}
	}
}
#footerSpLinks {
	@include mq(pc) {
		display: none;
	}
	@include mq(tab) {
		display: none;
	}
	@include mq(sp) {
		padding: 20px 0 0;
		overflow: hidden;
		div.footerBridal a,
		div.footerPc a {
			display: block;
			margin: 0 4% 20px;
			padding: 20px 15px;
			text-decoration: none;
		}
		div.footerBridal a {
			background: $gray;
			color: $white;
			font-size: 16px;
			font-size: 1.6rem;
			letter-spacing: 0.1em;
			font-family: Halant, $fontSerif;
			&::after {
				content: '';
				display: inline-block;
				float: right;
				height: 1.3em;
				width: 1.3em;
				overflow: hidden;
				background: url('/img/common/icon_blank2_white.svg') center center no-repeat;
				background-size: contain;
			}
		}
		div.footerPc a {
			background: $white;
			text-align: center;
			border: solid 1px $grayL;
			color: $fontColor;
			color: $gray;
			font-size: 16px;
			font-size: 1.6rem;
			&::before {
				content: '';
				display: inline-block;
				height: 1.6em;
				width: 2em;
				margin-right: 1em;
				vertical-align: bottom;
				overflow: hidden;
				background: url('/img/common/icon_pc.svg') center bottom no-repeat;
				background-size: contain;

			}
		}
	}
}

#footerBottom {
	background: $gray;
	text-align: center;
	padding: 10px 0;
	small.copy {
		color: $grayL;
		font-size: 10px;
		font-size: 1.0rem;
		letter-spacing: 0.1em;
	}
}


// ==================================
// jsでのメディアチェック
// ==================================
div.mq {
	@include mq(pc) {
		width: 1px;
	}
	@include mq(tab) {
		width: 2px;
	}
	@include mq(sp) {
		width: 3px;
	}
}
div.mqTab {
	@include mq(tabL) {
		width: 1px;
	}
	@include mq(tabS) {
		width: 2px;
	}
}
