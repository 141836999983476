@charset "UTF-8";
// ==================================
// mypage common
// ==================================
div.mypageWrap {
	@extend .formWrap;
	&.contentsTop {
		padding-top: 0;
	}
	@include mq(pc) {
		padding: 70px 0 20px;
	}
	@include mq(tab) {
		padding: 40px 0 20px;
	}
	@include mq(sp) {
		padding: 15px 0;
	}
	div.mypageNavWrap {
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
		}
		@include mq(tab) {
			margin: 0 2%;
		}
		ul.mypageNav {
			@extend %clearfix;
			padding: 20px 0;
			& > li {
				box-sizing: border-box;
				float: left;
				text-align: left;
				border: solid 1px $gray;
				position: relative;
				margin: 0;
				$paddingPc: 17px 20px;
				$paddingTab: 17px 15px;
				$paddingSp: 8px 5%;
				a {
					text-decoration: none;
					display: block;
				}
				@include mq(pc) {
					width: 20%;
					font-size: 16px;
					font-size: 1.6rem;
					border-right: 0;
					a {
						padding: $paddingPc;
					}
					&::after {
						width: 10px;
						height: 58px;
					}
				}
				@include mq(tab) {
					width: 33.33%;
					font-size: 16px;
					font-size: 1.6rem;
					border-top: 0;
					border-left: 0;
					&:first-child,
					&:nth-child(-n+3) {
						border-top: solid 1px $gray;
					}
					&:nth-child(3n+1) {
						border-left: solid 1px $gray;
					}
					a {
						padding: $paddingTab;
					}
					&::after {
						width: 7px;
						height: 58px;
					}
				}
				@include mq(sp) {
					width: 50%;
					font-size: 14px;
					font-size: 1.4rem;
					border-top: 0;
					border-left: 0;
					&:first-child,
					&:nth-child(2) {
						border-top: solid 1px $gray;
					}
					&:nth-child(even) {
						border-right: 0;
					}
					a {
						padding: $paddingSp;
					}
					&::after {
						width: 7px;
						height: 38px;
					}
				}
				&::after {
					content: '';
					display: block;
					position: absolute;
					background: url('/img/common/icon_arrow_right_gray.svg') center center no-repeat;
					background-size: contain;
					top: 0;
					right: 6%;
				}
				&:last-child {
					margin-right: 0;
					border-right: solid 1px $gray;
				}
				&.active {
					background: $grayLL;
					span {
						display: block;
						@include mq(pc) {
							padding: $paddingPc;
						}
						@include mq(tab) {
							padding: $paddingTab;
						}
						@include mq(sp) {
							padding: $paddingSp;
						}
					}
				}
			}
		}
	}
	div.holdingPoint {
		@extend %clearfix;
		background: $grayLL;
		box-sizing: border-box;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 10px 10%;
		}
		@include mq(tab) {
			padding: 10px 2%;
		}
		@include mq(sp) {
			padding: 10px 4%;
		}
		div.pointIcon {
			background: url('/img/common/icon_point.svg') center center no-repeat;
			background-size: contain;
			vertical-align: top;
			@include mq(pc) {
				display: inline-block;
				width: 28px;
				height: 28px;
				margin: 6px 5px 0 0;
			}
			@include mq(tab) {
				display: inline-block;
				width: 28px;
				height: 28px;
				margin: 6px 5px 0 0;
			}
			@include mq(sp) {
				float: left;
				width: 25px;
				height: 25px;
				margin: 0 5px 0 0;
			}
		}
		div.txtWrap {
			vertical-align: top;
			@include mq(pc) {
				display: inline-block;
			}
			@include mq(tab) {
				display: inline-block;
			}
			@include mq(sp) {
				padding: 3px 0 0 30px;
				line-height: 1.3;
			}
		}
		div.txtWrap > p {
			@include mq(pc) {
				display: inline-block;
			}
			@include mq(tab) {
				display: inline-block;
			}
			@include mq(sp) {
				display: inline;
				font-size: 12px;
				font-size: 1.2rem;
			}
		}
		p.title {
			font-weight: bold;
		}
		p.point {
			font-weight: bold;
			color: $red;
			@include mq(pc) {
				span.strong {
					font-size: 24px;
					font-size: 2.4rem;
				}
			}
			@include mq(tab) {
				span.strong {
					font-size: 24px;
					font-size: 2.4rem;
				}
			}
			@include mq(sp) {
				span.strong {
					font-size: 14px;
					font-size: 1.4rem;
				}
			}
		}
	}
	div.introWrap {
		@include mq(pc) {
			padding: 20px 0;
		}
		@include mq(tab) {
			padding: 20px 0 10px;
		}
		@include mq(sp) {
			padding: 15px 0 5px;
		}
		h3 {
			padding-bottom: 0;
			margin-bottom: 10px;
			@include mq(pc) {
				font-weight: normal;
			}
			@include mq(tab) {
				font-weight: normal;
			}
		}
	}

	div.resultTxt {
		@include mq(pc) {
			font-size: 24px;
			font-size: 2.4rem;
			span.title, span.end {
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		@include mq(tab) {
			font-size: 24px;
			font-size: 2.4rem;
			span.title, span.end {
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		@include mq(sp) {
			padding: 0 4%;
			span.title, span.end {
				font-size: 10px;
				font-size: 1.0rem;
			}
		}
	}

	div.displayNum {
		span.title {
			display: inline-block;
			vertical-align: top;
			@include mq(pc) {
				padding: 14px 1em 0 0;
			}
			@include mq(tab) {
				padding: 0 0 3px 3px;
			}
			@include mq(sp) {
				padding: 7px 0 0;
			}
		}
		div.selectWrap {
			display: inline-block;
			@include mq(pc) {
				width: 8em;
			}
			@include mq(tab) {
				width: 8em;
			}
			@include mq(sp) {
				width: 6em;
			}
		}
		select {
			@include mq(pc) {
				padding-top: 10px;
				padding-bottom: 10px;
				font-size: 18px;
				font-size: 1.8rem;
			}
			@include mq(tab) {
				font-size: 16px;
				font-size: 1.6rem;
			}
		}
	}

}



// ==================================
// mypageTop
// ==================================
div.mypageTop {
	div.purchaseHistory {
		@include mq(pc) {
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			padding-right: 2%;
			padding-left: 2%;
		}
	}
	div.historyTableWrap {
		table.historyTable {
			box-sizing: border-box;
			border-top: solid 1px $grayL;
			width: 100%;
			@include mq(tab) {
				display: block;
			}
			@include mq(sp) {
				display: block;
			}
		}
		tbody {
			@include mq(tab) {
				display: block;
			}
			@include mq(sp) {
				display: block;
			}
		}
		tr {
			border-bottom: solid 1px $grayL;
			overflow: hidden;
			@include mq(pc) {
				&.sp { display: none; }
			}
			@include mq(tab) {
				display: block;
				overflow: hidden;
			}
			@include mq(sp) {
				display: block;
				overflow: hidden;
			}
		}
		th {
			box-sizing: border-box;
			@include mq(pc) {
				padding: 15px 0;
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: 400;
			}
			@include mq(tab) {
				padding: 15px 1%;
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: 400;
				display: block;
				float: left;
			}
			@include mq(sp) {
				padding: 10px 1%;
				font-size: 10px;
				font-size: 1.0rem;
				font-weight: 400;
				display: block;
				float: left;
			}
		}
		td {
			vertical-align: middle;
			box-sizing: border-box;
			@include mq(pc) {
				padding: 15px 1%;
			}
			@include mq(tab) {
				padding: 15px 1%;
				display: block;
				float: left;
			}
			@include mq(sp) {
				padding: 15px 1%;
				font-size: 10px;
				font-size: 1.0rem;
				display: block;
				float: left;
			}
		}
		th.orderNum,
		th.orderDate,
		td.orderNum,
		td.orderDate {
			text-align: center;
			@include mq(pc) {
				width: 10.5%;
			}
			@include mq(tab) {
				width: 17%;
			}
			@include mq(sp) {
				width: 21%;
			}
		}
		th.orderItem,
		td.orderItem {
			@include mq(pc) {
				width: 54%;
			}
			@include mq(tab) {
				width: 32%;
			}
			@include mq(sp) {
				width: 24%;
			}
		}
		th.orderPrice,
		th.orderStatus,
		td.orderPrice,
		td.orderStatus {
			text-align: center;
			@include mq(pc) {
				width: 9.5%;
			}
			@include mq(tab) {
				width: 17%;
			}
			@include mq(sp) {
				width: 17%;
			}
		}
		th.orderDetail {
			@include mq(tab) {
				clear: both;
				width: 100%;
				display: none;
			}
			@include mq(sp) {
				clear: both;
				width: 100%;
				display: none;
			}
		}
		td.orderDetail {
			@include mq(pc) {
				width: 6%;
				padding-right: 0;
				padding-left: 0;
			}
			@include mq(tab) {
				clear: both;
				width: 100%;
			}
			@include mq(sp) {
				clear: both;
				width: 100%;
				padding: 0 0 15px;
			}
			a {
				display: inline-block;
				width: 100%;
				text-decoration: none;
				text-align: center;
				color: $grayD;
				background: $grayLL;
				border: solid 1px $grayL;
				@include mq(pc) {
					padding: 10px 0;
				}
				@include mq(tab) {
					padding: 15px 0;
					margin: 5px 0 15px;
				}
				@include mq(sp) {
					font-size: 14px;
					font-size: 1.4rem;
					padding: 5px 0;
					margin: 5px 0 0;
				}
			}
		}
	}
}


// ==================================
// mypage user edit
// ==================================
div.mypageUserEdit {
	table.formInputTable {
		br.sp {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none; }
		}
	}
}
div.mypageUserComplete {
	div.introWrap {
		@include mq(sp) {
			margin-bottom: 30px;
		}
	}
}


// ==================================
// mypage user edit
// ==================================
div.mypageHistoryDetail {
	div.historyDetailContent {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			padding-right: 2%;
			padding-left: 2%;
		}
	}
	h4 {
		background: $grayL;
		font-weight: 400;
		margin-bottom: 10px;
		@include mq(pc) {
			padding: 3px 1%;
			font-size: 16px;
			font-size: 1.6rem;
		}
		@include mq(tab) {
			padding: 3px 1%;
			font-size: 16px;
			font-size: 1.6rem;
		}
		@include mq(sp) {
			padding: 8px 4%;
		}
	}
	table {
		box-sizing: border-box;
		border-top: solid 1px $grayL;
		width: 100%;
		tr {
			border-bottom: solid 1px $grayL;
		}
		td {
			vertical-align: middle;
		}
	}
	div.orderStatusTableWrap,
	div.orderPersonTableWrap {
		h4 {
			@include mq(tab) {
				padding-left: 2%;
			}
		}
		@include mq(pc) {
			width: 48.5%;
			float: left;
			&:nth-child(odd) {
				margin-right: 3%;
			}
		}
		@include mq(tab) {
			width: 48.5%;
			float: left;
			&:nth-child(odd) {
				margin-right: 3%;
			}
		}
		@include mq(sp) {
			&:nth-child(3n-1) {
				margin-top: 20px;
			}
		}
		th {
			text-align: left;
			font-weight: normal;
			@include mq(pc) {
				padding: 10px 0;
				font-size: 16px;
				font-size: 1.6rem;
				width: 27%;
			}
			@include mq(tab) {
				padding: 10px 0;
				font-size: 16px;
				font-size: 1.6rem;
				width: 27%;
			}
			@include mq(sp) {
				padding: 20px 4%;
				background: $grayLL;
				width: 29%;
			}
		}
		td {
			@include mq(pc) {
				padding: 10px 0;
			}
			@include mq(tab) {
				padding: 10px 4%;
			}
			@include mq(sp) {
				padding: 20px 4%;
			}
		}
	}
	div.orderDetailTableWrap {
		@include mq(pc) {
			padding-top: 50px;
			clear: both;
		}
		@include mq(tab) {
			padding-top: 30px;
			clear: both;
		}
		@include mq(sp) {
			padding-top: 20px;
		}
		table.orderDetailTable {
			@include mq(tab) {
				display: block;
			}
			@include mq(sp) {
				display: block;
			}
		}
		tbody {
			@include mq(sp) {
				display: block;
			}
		}
		tr {
			overflow: hidden;
			@include mq(tab) {
				display: block;
			}
			@include mq(sp) {
				display: block;
			}
		}
		td {
			@include mq(pc) {
				box-sizing: border-box;
				padding: 15px 1%;
			}
			@include mq(tab) {
				display: block;
				&.adjustTd {
					display: none;
				}
			}
			@include mq(sp) {
				display: block;
				&.adjustTd {
					display: none;
				}
			}
			br.sp {
				@include mq(pc) { display: none; }
				@include mq(tab) { display: none; }
			}
		}
		td.itemId {
			@include mq(pc) {
				width: 15.5%;
				padding-left: 0;
			}
			@include mq(tab) {
				padding: 15px 2% 5px;
			}
			@include mq(sp) {
				padding: 15px 4% 5px;
			}
		}
		td.itemName {
			@include mq(pc) {
				width: 45%;
			}
			@include mq(tab) {
				padding: 5px 2%;
			}
			@include mq(sp) {
				padding: 5px 4%;
			}
			p.brand {
				@include mq(pc) {
					display: inline;
					margin-right: 1em;
				}
				@include mq(tab) {
					font-weight: bold;
				}
				@include mq(sp) {
					font-weight: bold;
				}
			}
			p.name {
				display: inline;
				margin-right: 1em;
				@include mq(tab) {
					font-weight: bold;
				}
				@include mq(sp) {
					font-weight: bold;
				}
			}
			p.spec {
				display: inline;
				@include mq(tab) {
					font-weight: bold;
				}
				@include mq(sp) {
					font-weight: bold;
				}
			}
		}
		td.price,
		td.itemNum,
		td.subtotal {
			@include mq(tab) {
				padding: 5px 0 2% 5px;
				float: left;
				text-align: right;
				box-sizing: border-box;
			}
			@include mq(sp) {
				padding: 5px 4%;
			}
		}
		td.price {
			box-sizing: border-box;
			@include mq(pc) {
				width: 17%;
			}
			@include mq(tab) {
				margin-left: 40%;
				width: 27%;
			}
			span.small {
				font-size: 10px;
				font-size: 1.0rem;
			}
		}
		td.itemNum {
			box-sizing: border-box;
			@include mq(pc) {
				width: 9%;
			}
			@include mq(tab) {
				width: 13%;
			}
			@include mq(sp) {
				padding-bottom: 15px;
			}
		}
		td.subtotal {
			@include mq(pc) {
				text-align: right;
				padding-right: 0;
			}
			@include mq(tab) {
				width: 20%;
			}
		}
		td.itemSumWrap,
		td.usePoint,
		td.totalWrap,
		td.fee,
		td.getPoint {
			@include mq(pc) {
				padding: 20px 0;
			}
			@include mq(tab) {
				padding: 20px 0;
			}
			@include mq(sp) {
				padding: 15px 0;
			}
			& > div {
				overflow: hidden;
				margin-bottom: 10px;
				&:last-child {
					margin-bottom: 0;
				}
			}
			p {
				display: inline-block;
				&.title {
					float: left;
					@include mq(tab) {
						margin-left: 70%;
					}
					@include mq(sp) {
						margin-left: 4%;
					}
				}
				&.value {
					float: right;
					@include mq(sp) {
						margin-right: 4%;
					}
				}
				span.red {
					color: $red;
				}
			}
		}
		td.totalWrap {
			font-size: 18px;
			font-size: 1.8rem;
			font-weight: 600;
		}
	}
	div.orderDeliveTableWrap {
		@include mq(pc) {
			padding-top: 50px;
		}
		@include mq(tab) {
			padding-top: 30px;
			padding-bottom: 30px;
		}
		@include mq(sp) {
			padding-top: 20px;
			padding-bottom: 20px;
		}
		th {
			text-align: left;
			font-weight: normal;
			@include mq(pc) {
				padding: 15px 0;
				font-size: 16px;
				font-size: 1.6rem;
				width: 15%;
			}
			@include mq(tab) {
				padding: 10px 0;
				font-size: 16px;
				font-size: 1.6rem;
				width: 27%;
			}
			@include mq(sp) {
				padding: 20px 4%;
				background: $grayLL;
				width: 29%;
			}
		}
		td {
			@include mq(pc) {
				padding: 15px 0;
			}
			@include mq(tab) {
				padding: 10px 0;
			}
			@include mq(sp) {
				padding: 20px 4%;
			}
		}
	}
}

// ==================================
// mypage delivery list
// ==================================
div.mypageDeliveryList {
	div.deliveryList {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin-right: auto;
			margin-left: auto;
		}
		@include mq(tab) {
			padding-right: 2%;
			padding-left: 2%;
		}
	}
	div.resultTxt {
		float: left;
		@include mq(pc) {
			padding: 10px 0 20px;
		}
		@include mq(tab) {
			padding: 10px 0 20px;
		}
		@include mq(sp) {
			margin: 15px 0 10px 4%;
			padding: 7px 0 0;
		}
	}
	div.displayNum {
		float: right;
		@include mq(pc) {
			margin-bottom: 20px;
		}
		@include mq(tab) {
			margin-bottom: 20px;
		}
		@include mq(sp) {
			margin: 15px 4% 10px 0;
			text-align: right;
		}
	}

	div.deliveryTableWrap {
		clear: both;
		table.deliveryTable {
			margin-bottom: 30px;
			border-top: solid 1px $grayL;
			width: 100%;
			&:first-child {
				@include mq(tab) {
					border: 0;
				}
				@include mq(sp) {
					border: 0;
				}
			}
			tbody {
				@include mq(tab) {
					display: block;
				}
				@include mq(sp) {
					display: block;
				}
			}
			tr {
				border-bottom: solid 1px $grayL;
				overflow: hidden;
				@include mq(tab) {
					display: block;
					overflow: hidden;
				}
				@include mq(sp) {
					display: block;
					overflow: hidden;
				}
			}
			th {
				@include mq(pc) {
					padding: 15px 0;
					font-size: 16px;
					font-size: 1.6rem;
					font-weight: 400;
				}
				@include mq(tab) {
					display: none;
				}
				@include mq(sp) {
					display: none;
				}
			}
			td {
				vertical-align: middle;
				box-sizing: border-box;
				@include mq(pc) {
					padding: 15px 1%;
				}
				@include mq(tab) {
					display: block;
				}
				@include mq(sp) {
					display: block;
				}
			}
			td.delivId {
				text-align: center;
				@include mq(tab) {
					line-height: 50px;
					float: left;
					padding-left: 2%;
					padding-right: 2%;
				}
				@include mq(sp) {
					line-height: 50px;
					float: left;
					padding-left: 4%;
					padding-right: 4%;
				}
			}
			td.type {
				@include mq(pc) {
					text-align: center;
					padding-left: 30px;
					padding-right: 30px;
				}
				@include mq(tab) {
					line-height: 50px;
					background: $grayLL;
					border-bottom: solid 1px $grayL;
				}
				@include mq(sp) {
					line-height: 50px;
					background: $grayLL;
					border-bottom: solid 1px $grayL;
				}
			}
			td.address {
				@include mq(tab) {
					box-sizing: border-box;
					width: 76%;
					padding: 4%;
					float: left;
				}
				@include mq(sp) {
					box-sizing: border-box;
					width: 76%;
					padding: 4%;
					float: left;
				}
			}
			td.edit {
				@include mq(pc) {
					width: 10%;
				}
				@include mq(tab) {
					display: block;
					clear: both;
					width: 100%;
				}
				@include mq(sp) {
					display: block;
					clear: both;
					width: 100%;
				}
				a {
					display: inline-block;
					width: 100%;
					text-decoration: none;
					text-align: center;
					color: inherit;
					background: $white;
					border: solid 1px $grayL;
					@include mq(pc) {
						padding: 9px 0;
					}
					@include mq(tab) {
						padding: 14px 0;
					}
					@include mq(sp) {
						padding: 4px 0;
					}
				}
			}
			td.itemDel {
				@include mq(pc) {
					width: 10%;
				}
				@include mq(tab) {
					display: block;
					clear: both;
					width: 100%;
				}
				@include mq(sp) {
					display: block;
					clear: both;
					width: 100%;
				}
				a {
					display: inline-block;
					width: 100%;
					text-decoration: none;
					text-align: center;
					color: inherit;
					border: solid 1px $grayL;
					background: $grayL;
					@include mq(pc) {
						padding: 9px 0;
					}
					@include mq(tab) {
						padding: 14px 0;
					}
					@include mq(sp) {
						padding: 4px 0;
					}
				}
			}
			td.edit,
			td.itemDel {
				@include mq(pc) {
					width: 8%;
				}
				@include mq(tab) {
					width: 20%;
					float: left;
					clear: none;
					margin-top: 4%;
					&:last-child {
						margin-bottom: 4%;
					}
					a {
						margin: 0;
					}
				}
				@include mq(sp) {
					width: 20%;
					float: left;
					clear: none;
					margin-top: 4%;
					&:last-child {
						margin-bottom: 4%;
					}
					a {
						margin: 0;
					}
				}
			}
		}
	}
}

// ==================================
// mypage Refusal
// ==================================
div.mypageRefusal {
	div.introWrap {
		@include mq(pc) {
			margin-bottom: 25px;
		}
		@include mq(tab) {
			margin-bottom: 20px;
		}
		@include mq(sp) {
			margin-bottom: 15px;
		}
	}
	div.refusalWrap {
		border: solid 1px $red;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 25px 0 0;
		}
		@include mq(tab) {
			margin: 0 2%;
			padding: 20px;
		}
		@include mq(sp) {
			border-right: 0;
			border-left: 0;
			padding: 20px 0;
		}
		p {
			color: $red;
			@include mq(pc) {
				text-align: center;
			}
			@include mq(tab) {
				text-align: center;
			}
			@include mq(sp) {
				padding: 0 4% 15px;
			}
		}
	}
}
