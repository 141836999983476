@charset "UTF-8";

// ==================================
// Bread List
// ==================================
div.partsBreadList {
	background: $gray;
	div.breadList {
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 8px 0;
		}
		@include mq(tab) {
			margin: 0 2%;
			padding: 8px 0;
		}
		@include mq(sp) {
			margin: 0 4%;
			padding: 3px 0;
		}
		& > span, a {
			color: $white;
			@include mq(pc) {
				font-size: 12px;
				font-size: 1.2rem;
			}
			@include mq(tab) {
				font-size: 12px;
				font-size: 1.2rem;
			}
			@include mq(sp) {
				font-size: 10px;
				font-size: 1.0rem;
				line-height: 1;
			}
		}
		& > span {
			&::after {
				content: '>';
				margin: 0 0.3em;
			}
			&:last-child::after {
				content: '';
			}
		}
	}
}

// ==================================
// Search Refine
// ==================================
div.searchRefine,
div.displayTypeBottom {
	div.spTitle {
		@include mq(pc) { display: none; }
		@include mq(tab) { display: none;}
		@include mq(sp) {
			background: $grayD;
			font-size: 16px;
			font-size: 1.6rem;
			text-align: 1;
			color: $white;
			padding: 20px 0;
			text-align: center;
			&::before {
				content: '';
				display: inline-block;
				background: url('/img/common/icon_search_white.svg') center center no-repeat;
				background-size: contain;
				width: 30px;
				height: 30px;
				margin-right: 10px;
				vertical-align: middle;
			}
		}
	}
	div.conditionWrap {
		margin: 0 auto;
		@include mq(pc) {
			width: $widthPc;
		}
		@include mq(tab) {
		}
		@include mq(sp) {
		}
		h4 {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none;}
			@include mq(sp) {
				margin: 10px 4% 0;
				font-weight: 100;
			}
		}
		ul.conditionList {
			@extend %clearfix;
			border-top: solid 1px $grayL;
			border-bottom: solid 1px $grayL;
			@include mq(pc) {
				padding: 15px 0 5px;
			}
			@include mq(tab) {
				padding: 15px 2% 5px;
			}
			@include mq(sp) {
				padding: 10px 4% 0;
			}
			& > li {
				float: left;
				@include mq(pc) {
					margin: 0 35px 10px 0;
					&:hover {
						text-decoration: underline;
					}
				}
				@include mq(tab) {
					margin: 0 30px 10px 0;
					&:hover {
						text-decoration: underline;
					}
				}
				@include mq(sp) {
					margin: 0 20px 10px 0;
					font-size: 12px;
					font-size: 1.2rem;
				}
				a {
					display: block;
					text-decoration: none;
					color: $fontColor;
					&::after {
						content: '';
						display: inline-block;
						border: solid 1px $grayL;
						background: $grayLL url('/img/common/icon_cross.svg') center center no-repeat;
						background-size: 10px;
						height: 19px;
						width: 17px;
						vertical-align: bottom;
						@include mq(pc) {
							margin-left: 10px;
						}
						@include mq(tab) {
							margin-left: 10px;
						}
						@include mq(sp) {
							margin-left: 5px;
						}
					}
				}
			}
		}
	}
	div.displayTypeWrap {
		h4 {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none;}
			@include mq(sp) {
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: 100;
				padding: 20px 4%;
				&::after {
					transition: transform 0.2s;
					content: '';
					background: url('/img/common/icon_tri_right.svg') center center no-repeat;
					background-size: contain;
					display: inline-block;
					margin-top: 5px;
					float: right;
					width: 15px;
					height: 15px;
					transform: rotate(90deg);
				}
			}
		}
		&.open {
			@include mq(sp) {
				div.displayType {
					transition: max-height 0.4s;
					max-height: 1000px;
				}
				h4::after {
					transition: transform 0.2s;
					transform: rotate(-90deg);
				}
			}
		}
	}
	div.displayType {
		@include mq(sp) {
			transition: max-height 0.4s;
			max-height: 0;
			overflow: hidden;
			border-bottom: solid 1px $grayL;
		}
		div.submitRefine {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none;}
			@include mq(sp) {
				display: block;
				width: 92%;
				margin: 20px auto;
				input {
					color: $white;
					text-align: center;
					padding: 20px 0;
					background: $gray url('/img/common/icon_tri_right_white.svg') 94% center no-repeat;
					background-size: 12px;
					width: 114.28%;
				}
			}
		}
	}
	ul.displayTypeList {
		@extend %clearfix;
		overflow: hidden;
		@include mq(pc) {
			width: $widthPc;
			margin: 25px auto 0;
		}
		@include mq(tab) {
			margin: 10px 4% 0;
		}
		@include mq(sp) {
			border-top: solid 1px $grayL;
		}
		& > li {
			@extend %clearfix;
			@include mq(pc) {
				float: left;
				margin: 0 35px 10px 0;
			}
			@include mq(tab) {
				float: left;
				margin: 0 30px 10px 0;
			}
			@include mq(sp) {
				border-bottom: dotted 1px $grayL;
			}
		}
		span.title {
			display: block;
			@include mq(pc) {
				float: left;
				padding: 15px 1em 15px 0;
			}
			@include mq(tab) {
				padding: 0 0 3px 3px;
			}
			@include mq(sp) {
				float: left;
				width: 25%;
				padding: 20px 4%;
				background: $grayLL;
			}
		}
		div.selectWrap {
			display: block;
			border: solid 1px $grayL;
			overflow: hidden;
			@include mq(pc) {
				float: left;
				width: 110px;
			}
			@include mq(tab) {
				width: 110px;
			}
			@include mq(sp) {
				float: left;
				box-sizing: border-box;
				margin: 15px 4% 0;
				width: 59%;
				height: 35px;
			}
		}
		select {
			width: 110%;
			background: url('/img/common/select_btn.svg') 90% center no-repeat;
			border: 0;
			@include mq(sp) {
				width: 125%;
				height: 114.28%;
			}
		}
	}
}
div.displayTypeBottom {
	@include mq(pc) { display: none; }
	@include mq(tab) { display: none; }
	@include mq(sp) {
		.displayTypeWrap{
			margin-top: 20px;
			border-top: solid 1px $grayL;
		}
	}
}



// ==================================
// Search Result
// ==================================
#itemlistResultWrap {
	@include mq(pc) {
		width: $widthPc;
		margin: 0 auto 80px;
	}
	@include mq(tab) {
		margin: 0 2% 60px;
	}
	@include mq(sp) {
		margin-bottom: 40px;
		ul.colList {
			margin: 0 4%;
		}
	}
	div.resultTxt {
		@include mq(pc) {
			padding-bottom: 25px;
			font-size: 24px;
			font-size: 2.4rem;
			span.title, span.end {
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		@include mq(tab) {
			padding-bottom: 25px;
			font-size: 24px;
			font-size: 2.4rem;
			span.title, span.end {
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
		@include mq(sp) {
			padding: 10px 4%;
			span.title, span.end {
				font-size: 10px;
				font-size: 1.0rem;
			}
		}
	}
	ul.colList {
		@include mq(sp) {
			padding: 10px 0;
			& > li {
				width: 32%;
				margin-right: 2%;
				margin-bottom: 20px;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
		li > a:hover,
		li > a:hover * {
			opacity: 1;
		}
		div.detail {
			h3.hoverT {
				bottom: 45%;
				@include mq(pc) {
					font-size: 18px;
					font-size: 1.8rem;
					letter-spacing: 3px;
				}
				@include mq(tab) {
					font-size: 15px;
					font-size: 1.5rem;
					letter-spacing: 2px;
				}
				@include mq(sp) {
				}
			}
			div.hoverC {
				top: 60%;
			}
			div.arrowBottom {
				width: 10px;
				height: 10px;
				margin: 0 auto;
				border-bottom: solid 1px $white;
				border-right: solid 1px $white;
				transform: rotate(45deg);
			}
		}
		div.txtWrap {
			@extend %clearfix;
			padding: 10px 0;
			@include mq(sp) {
				padding: 0;
			}
			p.brand {
				color: $gray;
				font-size: 12px;
				font-size: 1.2rem;
				font-weight: bold;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				@include mq(sp) {
					font-size: 10px;
					font-size: 1.0rem;
				}
			}
			p.name {
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: bold;
				overflow: hidden;
				height: 52px;
				padding-bottom: 3px;
				@include mq(sp) {
					font-size: 12px;
					font-size: 1.2rem;
					height: 40px;
				}
			}
			p.specprice {
				@include mq(sp) {
					font-size: 11px;
					font-size: 1.1rem;
				}
			}
			span.spec {
				margin-right: 3px;
			}
		}
	}

}
.pagerWrap {
	text-align: center;
	ul {
		@extend %clearfix;
		display: inline-block;
		@include mq(sp) {
			display: block;
		}
		& > li {
			float: left;
			font-size: 22px;
			font-size: 2.2rem;
			line-height: 45px;
			margin-right: 10px;
			@include mq(sp) {
				font-size: 16px;
				font-size: 1.6rem;
				line-height: 1;
			}
			@include mq(sp) {
				width: 9.37%;
				position: relative;
				margin-right: 1.95%;
				&::before {
					content: '';
					display: block;
					padding-top: 100%;
				}
			}
			&::last-child {
				margin-right: 0;
			}
			a {
				display: block;
				@extend .ffHalant;
				text-decoration: none;
				color: $fontColor;
				width: 45px;
				height: 45px;
				border: solid 1px $grayL;
				background: $white;
				@include mq(sp) {
					position: absolute;
					width: auto;
					height: auto;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					line-height: 30px;
				}
			}
			&.active a {
				background: $grayL;
			}
			&.prev a, &.next a {
				background: $grayD;
				width: 70px;
				position: relative;
				border: 0;
				&::after {
					content: '';
					display: block;
					position: absolute;
					border-top: solid 1px $white;
					border-right: solid 1px $white;
					width: 10px;
					height: 10px;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					margin: auto;
				}
				@include mq(sp) {
					position: absolute;
					width: auto;
				}
			}
			&.prev {
				margin-right: 20px;
				a::after {
					transform: rotate(-135deg);
				}
				@include mq(sp) {
					margin-right: 3.90%;
					width: 18.75%;
					&::before {
						padding-top: 48.33%;
					}
				}
			}
			&.next {
				margin-left: 10px;
				a::after {
					transform: rotate(45deg);
				}
				@include mq(sp) {
					width: 18.75%;
					margin-left: 1.95%;
					margin-right: 0;
					&::before {
						padding-top: 48.33%;
					}
				}
			}
			&.ellipsis {
				span {
					@include mq(sp) {
						display: block;
						position: absolute;
						top: 0;
						right: 0;
						bottom: 0;
						left: 0;
						line-height: 30px;
					}
				}

			}
		}
	}
}



