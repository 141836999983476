/* #overview
mixin
*/

/* #styleguide
メディアクエリ

usage:
.foo {
	color: red;
	\@include mq(sp) {
	color: blue;
	}
}
*/
@mixin mq($bpName) {
	@media #{unquote(map-get($bpArr,$bpName))} {
		@content;
	}
}

/* #styleguide
clearfix

usage:
div { @extend %clearfix; }
*/
%clearfix {
	&:after {
		content: "";
		display: table;
		clear: both;
	}
}

/* #styleguide
天地中央寄せ

usage:
div { @include vertical-align(); }
*/
@mixin vertical-align {
	position: relative;
	top: 50%;
	transform: translateY(-50%);
}

/* #styleguide
小数点第２で

usage:
$num: 0.33;
四捨五入
round-p2nd($num) -> 0.33
切り上げ
ceil-p2nd($num) -> 0.34
切り捨て
floor-p2nd($num) -> 0.33
*/
@function round-p2nd($number) {
		@return (round($number * 100)) / 100;
}
@function ceil-p2nd($number) {
		@return (ceil($number * 100)) / 100;
}
@function floor-p2nd($number) {
		@return (floor($number * 100)) / 100;
}
