/* #overview
Setting / Color

ブレイクポイント
'sp' : 'only screen and (max-width: 767px)',
'tabs' : 'only screen and (max-width: 1000px)',
'tabL' : 'only screen and (min-width: 1001px) and (max-width: 1170px)',
'tab' : 'only screen and (min-width: 768px) and (max-width: 1170px)',
'pc' : 'only screen and (min-width: 1171px)',

コンテンツ横幅
PC 1170px
*/

// -------------------------------------------------------------------
// web font
// -------------------------------------------------------------------
@font-face{
	font-family: 'mplus-1p';
	src: url('/font/mplus-1p-regular.eot');
	src: url('/font/mplus-1p-regular.eot?#iefix') format('embedded-opentype'),
	url('/font/mplus-1p-regular.woff') format('woff'),
	url('/font/mplus-1p-regular.ttf') format('truetype');
	font-weight: normal;
}

@font-face{
	font-family: 'mplus-1p';
	src: url('/font/mplus-1p-medium.eot');
	src: url('/font/mplus-1p-medium.eot?#iefix') format('embedded-opentype'),
	url('/font/mplus-1p-medium.woff') format('woff'),
	url('/font/mplus-1p-medium.ttf') format('truetype');
	font-weight: 500;
}

@font-face{
	font-family: 'mplus-1p';
	src: url('/font/mplus-1p-bold.eot');
	src: url('/font/mplus-1p-bold.eot?#iefix') format('embedded-opentype'),
	url('/font/mplus-1p-bold.woff') format('woff'),
	url('/font/mplus-1p-bold.ttf') format('truetype');
	font-weight: 700;
}

// -------------------------------------------------------------------
// リセットcssの選択
// -------------------------------------------------------------------
@import 'reset/html5doctor';
ul { list-style: none; }
img { vertical-align: bottom; }
body { position: relative; }


// -------------------------------------------------------------------
// 設定
// -------------------------------------------------------------------
/*
#colors
@white #fff
@whiteD #fbfbfb
@black #333
@grayLL #f7f7f7
@grayL #dadada
@gray #999
@grayD #555
@blue #3390ca
@red #bc2020
@redL #f8e8e8
*/
$white: #fff;
$whiteD: #fbfbfb;
$black: #333;
$grayLL: #f7f7f7;
$grayL: #dadada;
$gray: #999;
$grayD: #555;
$blue: #3390ca;
$red: #bc2020;
$redL: #f8e8e8;

$fontColor: $black;


// ブレイクポイント
$bpArr:(
	'sp' : 'only screen and (max-width: 767px)',
	'tabS' : 'only screen and (max-width: 1000px)',
	'tabL' : 'only screen and (min-width: 1001px) and (max-width: 1170px)',
	'tab' : 'only screen and (min-width: 768px) and (max-width: 1170px)',
	'pc' : 'only screen and (min-width: 1171px)',
);

// 横幅
$widthPc: 1170px;


// ベースフォント
$fontSans: 'mplus-1p', 游ゴシック体, 'Yu Gothic', YuGothic, 'ヒラギノ角ゴシック Pro', 'Hiragino Kaku Gothic Pro', メイリオ, Meiryo, Osaka, 'ＭＳ Ｐゴシック', 'MS PGothic', sans-serif;
$fontSerif: "游明朝" , "Yu Mincho" , "游明朝体" , "YuMincho" , "ヒラギノ明朝 Pro W3" , "Hiragino Mincho Pro" , "HiraMinProN-W3" , "HGS明朝E" , "ＭＳ Ｐ明朝" , "MS PMincho" , serif;
$fontBase: $fontSans;
html { font-size: 62.5%; }
body {
	font-family: $fontBase;
	font-size: 1.4em;
	line-height: 1.6;
	color: $fontColor;
	letter-spacing: normal;
}

%linkHover {
	transition: opacity 0.5s;
	opacity: 0.5;
}
%linkOut {
	transition: opacity 0.5s;
}
a {
	@extend %linkOut;
	color: $blue;
}
a:hover {
	@extend %linkHover;
}


// -------------------------------------------------------------------
// keyframes
// -------------------------------------------------------------------
@keyframes show{
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}
@keyframes hide {
	0% {
		opacity: 1;
		height: auto;
	}
	99% {
		opacity: 0;
		height: auto;
	}
	100% {
		height: 0;
	}
}
