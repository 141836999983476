@charset "UTF-8";

// ==================================
// slider
// ==================================
.swBtnNext,
.swBtnPrev {
	position: absolute;
	top: 50%;
	width: 64px;
	height: 128px;
	margin-top: -32px;
	z-index: 10;
	cursor: pointer;
	background-size: 27px 44px;
	background-position: center;
	background-repeat: no-repeat;
	@include mq(sp) {
		display: none;
	}
}
.swBtnNext {
	right: 0;
	left: auto;
	background: url('/img/common/slider_btn_r.svg') right center no-repeat;
	background-size: contain;
}
.swBtnPrev {
	left: 0;
	right: auto;
	background: url('/img/common/slider_btn_l.svg') left center no-repeat;
	background-size: contain;
}
.swiper-slide {
	overflow: hidden;
	background-color: $white;
}
.swiper-pagination-bullet {
	border: 1px solid $gray;
	opacity: 1;
	background: none;
}
.swiper-pagination-bullet-active {
	background: $gray;
}

// ==================================
//
// Block Parts
//
// ==================================
// common --
// ---------------
div.partsWrap {
	h2 {
		@extend .ffHalant;
		line-height: 1;
		color: $fontColor;
		font-weight: 300;
		text-align: center;
		@include mq(pc) {
			font-size: 44px;
			font-size: 4.4rem;
			padding: 90px 0 20px;
		}
		@include mq(tab) {
			font-size: 44px;
			font-size: 4.4rem;
			padding: 90px 0 20px;
		}
		@include mq(sp) {
			font-size: 22px;
			font-size: 2.2rem;
			padding: 40px 0 20px;
		}
		span {
			display: block;
			&.en {
				letter-spacing: 0.1em;
			}
			&.jp {
				font-size: 14px;
				font-size: 1.4rem;
				color: $fontBase;
				font-weight: 400;
				color: gray;
				font-family: $fontBase;
			}
		}
	}
	a {
		color: inherit;
	}
}


// ==================================
// parts Hero Image
// ==================================
div.parts_hero {
	width: 100%;
	position: relative;
	&::before {
		content: '';
		display: block;
		@include mq(pc) {
			padding-top: 35.71%;
		}
		@include mq(tab) {
			padding-top: 35.71%;
		}
		@include mq(sp) {
			padding-top: 78.125%;
		}
	}
	.swiper-container {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		@include mq(pc) {
			&.sp { display: none; }
		}
		@include mq(tab) {
			&.sp { display: none; }
		}
		@include mq(sp) {
			&.pc { display: none; }
		}
	}
	.swiper-slide {
		text-align: center;
		& > * {
			background: center center no-repeat;
			background-size: cover;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
	}
}

// ==================================
// parts Item List
// ==================================
div.parts_itemlist {
	width: 100%;
	height: 170px;
	overflow: hidden;
	.swiper-slide {
		text-align: center;
		opacity: 0;
		transition: opacity 1.5s;
		&:before {
			content: '';
			display: block;
			padding-top: 100%;
		}
		& > * {
			background: center center no-repeat;
			background-size: cover;
			display: block;
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}
		a {
			text-decoration: none;
			color: $white;
			&:hover {
				opacity: 1;
				@include mq(sp) {
					.hoverTC {
						display: none;
					}
				}
			}
		}
	}
}

// ==================================
// parts Information Slide
// ==================================
div.parts_infoslide {
	width: 100%;
	max-height: 1000px;
	overflow: hidden;
	position: relative;
	background: $whiteD;
	padding-bottom: 20px;
	&::before {
		content: '';
		width: 50%;
		height: 100%;
		padding: 100% 0;
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		margin: auto;
		background: $grayLL;
		transform: rotate(45deg);
	}
	div.swiper-slide {
		@include mq(pc) {
			width: 100%;
		}
		@include mq(tab) {
			width: 100%;
		}
		@include mq(sp) {
			width: 92%;
		}
		background: none;
		a {
			@extend %clearfix;
			display: block;
			margin: 0 auto;
			color: inherit;
			text-decoration: none;
			@include mq(pc) {
				padding: 50px 20px;
				width: 970px;
			}
			@include mq(tab) {
				box-sizing: border-box;
				padding: 20px 2%;
				width: 100%;
			}
		}
		div.img {
			background: center center no-repeat;
			background-size: cover;
			&::before {
				content: '';
				display: block;
				padding-top: 61.75%;
			}
			@include mq(pc) {
				width: 58.76%;
				float: left;
			}
			@include mq(tab) {
				width: 58.76%;
				float: left;
			}
			@include mq(sp) {
				transition: filter 0.4s;
				filter: brightness(0.8);
			}
		}
		div.txtWrap {
			@include mq(pc) {
				float: left;
				width: 41.24%;
			}
			@include mq(tab) {
				float: left;
				width: 41.24%;
			}
			@include mq(sp) {
				transition: opacity 0.4s;
				opacity: 0;
			}
		}
		h3 {
			border-bottom: solid 1px $fontColor;
			@extend .ffHalant;
			line-height: 1;
			@include mq(pc) {
				display: inline-block;
				padding: 40px 0 0 20px;
				font-size: 60px;
				font-size: 6.0rem;
				font-weight: 300;
				letter-spacing: 0.06em;
				span.subTitle {
					font-size: 28px;
					font-size: 2.8rem;
					font-weight: 100;
					letter-spacing: 0.1em;
				}
			}
			@include mq(tab) {
				display: inline-block;
				padding: 0 0 0 20px;
				font-size: 60px;
				font-size: 6.0rem;
				font-weight: 300;
				letter-spacing: 0.06em;
				span.subTitle {
					font-size: 28px;
					font-size: 2.8rem;
					font-weight: 100;
					letter-spacing: 0.1em;
				}
			}
			@include mq(sp) {
				font-size: 22px;
				font-size: 2.2rem;
				font-weight: 100;
				text-align: center;
				letter-spacing: 0.1em;
				padding: 15px 0 10px;
			}
		}
		h4 {
			font-size: 20px;
			font-size: 2.0rem;
			@include mq(pc) {
				padding: 20px 0 0 20px;
			}
			@include mq(tab) {
				padding: 20px 0 0 20px;
			}
			@include mq(sp) {
				padding-top: 20px;
				font-weight: 300;
			}
		}
		p {
			@include mq(pc) {
				padding: 10px 0 0 20px;
			}
			@include mq(tab) {
				padding: 10px 0 0 20px;
			}
			@include mq(sp) {
				padding-top: 10px;
			}
		}
		.btnMore {
			@include mq(pc) {
				margin: 20px 0 0 20px;
			}
			@include mq(tab) {
				margin: 20px 0 0 20px;
			}
			@include mq(sp) {
				margin-top: 20px;
			}
		}
		@include mq(sp) {
			&.swiper-slide-active {
				div.img {
					transition: filter 0.4s;
					filter: brightness(1);
				}
				div.txtWrap {
					transition: opacity 0.4s;
					opacity: 1;
				}
			}
		}
	}
	div.paginationWrap {
		margin: 0 auto;
		position: relative;
		@include mq(pc) {
			padding: 0 20px;
			width: 970px;
			div.swiper-pagination {
				left: 58.76%;
				top: -70px;
				margin: 0 0 0 20px;
			}
		}
		@include mq(tab) {
			padding: 20px;
			div.swiper-pagination {
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}
		@include mq(sp) {
			padding: 20px;
			div.swiper-pagination {
				left: 0;
				right: 0;
				margin: 0 auto;
			}
		}
		.swiper-pagination-bullet {
			margin-right: 8px;
		}
	}
	div.swBtn {
		@include mq(tab) {
			display: none;
		}
		@include mq(sp) {
			display: none;
		}
	}
}


// ==================================
// ColList
// ==================================
// colList common
// ----------------------------------
ul.colList {
	@extend %clearfix;
	@include mq(pc) {
		width: $widthPc;
		margin: 0 auto;
	}
	@include mq(tab) {
		margin: 0 2%;
	}
	@include mq(sp) {
		margin: 0 4%;
	}
	& > li {
		float: left;
		@include mq(pc) {
			margin-right: 2.56%;
			margin-bottom: 2.56%;
		}
		@include mq(tab) {
			margin-right: 2.56%;
			margin-bottom: 2.56%;
		}
		@include mq(sp) {
			margin-bottom: 20px;
		}
	}
	a {
		@extend %clearfix;
		text-decoration: none;
		color: inherit;
		display: block;
		&:hover {
			opacity: 1;
			.img {
				@extend %linkHover;
			}
		}
		@include mq(sp) {
			border: solid 1px $grayL;
			padding: 7%;
		}
	}
	div.img {
		position: relative;
		background: center center no-repeat;
		background-size: cover;
		margin-bottom: 10px;
		&::before {
			content: '';
			display: block;
		}
	}
	h3 {
		@include mq(pc) {
			text-align: center;
			font-size: 20px;
			font-size: 2.0rem;
			font-weight: 300;
		}
		@include mq(tab) {
			text-align: center;
			font-size: 20px;
			font-size: 2.0rem;
			font-weight: 300;
		}
	}
	p.txt, p.txtS{
		padding-top: 10px;
	}
	p.txtS {
		font-size: 12px;
		font-size: 1.2rem;
	}
}

// 3colList
// ----------------------------------
div.parts_3col {
	padding-bottom: 30px;
	ul.colList {
		& li {
			@include mq(pc) {
				width: 31.62%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 31.62%;
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
			@include mq(sp) {
				width: 100%;
				box-sizing: border-box;
			}
		}
		div.img {
			&::before {
				padding-bottom: 62.16%;
			}
		}
	}
}

// 4colList
// ----------------------------------
div.parts_4col {
	padding-bottom: 30px;
	ul.colList {
		@include mq(sp) {
			margin: 0 0 30px 0;
			border-top: solid 1px $grayL;
		}
		& li {
			@include mq(pc) {
				width: 23.08%;
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 23.08%;
				&:nth-child(4n) {
					margin-right: 0;
				}
			}
			@include mq(sp) {
				width: 100%;
				margin: 0;
			}
		}
		a {
			@include mq(sp) {
				box-sizing: border-box;
				border-right: 0;
				border-left: 0;
				border-top: 0;
				padding: 4%;
			}
		}
		div.img {
			@include mq(sp) {
				width: 42%;
				float: left;
			}
			&::before {
				padding-bottom: 70.37%;
			}
		}
		div.txtWrap {
			@include mq(sp) {
				width: 53%;
				float: left;
				padding-left: 3%;
			}
			p.txtS {
				padding: 0;
				line-height: 1.2;
				font-size: 10px;
				font-size: 1.0rem;
			}
		}
	}
}
.voice {
	ul.colList > li {
		div.img {
			&::before {
				padding-bottom: 100%;
			}
		}
		div.txtWrap p.txtS {
			font-size: 12px;
			font-size: 1.2rem;
		}
	}
}



// 5colList
// ----------------------------------
div.parts_5col {
	padding-bottom: 30px;
	ul.colList {
		@include mq(sp) {
			margin: 0 0 30px 0;
			border: 0;
		}
		& li {
			@include mq(pc) {
				width: 17.95%;
				&:nth-child(5n) {
					margin-right: 0;
				}
			}
			@include mq(tab) {
				width: 17.95%;
				&:nth-child(5n) {
					margin-right: 0;
				}
			}
			@include mq(sp) {
				width: 33.33%;
				margin: 0;
				&:nth-child(3n) {
					width: 33.34%;
				}
			}
		}
		a {
			@include mq(sp) {
				box-sizing: border-box;
				border-right: 0;
				border-left: 0;
				border: 0;
				padding: 0
			}
		}
		div.img {
			@include mq(sp) {
				margin: 0;
			}
			&::before {
				padding-bottom: 100%;
			}
		}
		div.txtWrap {
			@include mq(sp) {
				padding: 0 10% 10%;
			}
		}
	}
}
.brandList {
	ul.colList {
		li > a:hover,
		li > a:hover * {
			opacity: 1;
		}
		div.img {
			border: solid 5px $white;
			@include mq(sp) {
				border: 0;
			}
		}
		div.txtWrap {
			width: 100%;
			display: table;
			box-sizing: border-box;
			p.txt {
				text-align: center;
				vertical-align: middle;
				display: table-cell;
				padding: 3px 5px 10px;
				img {
					max-width: 100%;
					height: auto;
				}
			}
		}
	}
}




// ==================================
// Parts Ranking
// ==================================
div.parts_ranking {
	@include mq(pc) {
		padding: 80px 0 0;
	}
	@include mq(tab) {
		padding: 60px 0 0;
	}
	@include mq(sp) {
	}
	ul.rankingList {
		@extend %clearfix;
		letter-spacing: -.4em;
		& > li {
			display: inline-block;
			letter-spacing: normal;
			vertical-align: bottom;
			counter-increment: rank;
			@include mq(pc) {
				width: 17.5%;
			}
			@include mq(tab) {
				width: 17.5%;
			}
			@include mq(sp) {
				width: 33.33%;
			}
			&:first-child {
				float: left;
				position: relative;
				@include mq(pc) {
					width: 30%;
				}
				@include mq(tab) {
					width: 30%;
				}
				@include mq(sp) {
					width: 66.66%;
				}
				&::after {
					content: '1';
					@extend .ffHalant;
					font-size: 90px;
					font-size: 9.0rem;
					font-weight: 100;
					line-height: 1;
					display: block;
					position: absolute;
					top: -40px;
					right: 40px;
					@include mq(sp) {
						font-size: 45px;
						font-size: 4.5rem;
						top: -20px;
						right: 20px;
					}
				}
				a {
					padding-top: 100%;
					&::after {
						content: 'RANKING';
						color: $fontColor;
						transform: rotate(90deg);
						transform-origin: right top;
						top: auto;
						bottom: 0;
						@include mq(pc) {
							width: 80%;
						}
						@include mq(tab) {
							width: 70%;
						}
						@include mq(sp) {
							width: 80%;
							font-size: 15px;
							font-size: 1.5rem;
						}
					}
					&::before {
						background: none;
						border-left: solid 1px $fontColor;
						width: 80px;
						height: 80px;
						top: 17px;
						right: -40px;
						@include mq(sp) {
							width: 40px;
							height: 40px;
							top: 9px;
							right: -20px;
						}
					}
				}
			}
		}
		a {
			position: relative;
			display: block;
			width: 100%;
			background: center center no-repeat;
			background-size: cover;
			box-sizing: border-box;
			text-decoration: none;
			color: $white;
			overflow: hidden;
			&:hover {
				opacity: 1;
				&::after {
					opacity: 0.6;
				}
			}
			@include mq(pc) {
				padding-top: 85.71%;
			}
			@include mq(tab) {
				padding-top: 85.71%;
			}
			@include mq(sp) {
				padding-top: 99.99%;
			}
			&::after {
				content: counter(rank);
				@extend .ffHalant;
				font-size: 32px;
				font-size: 3.2rem;
				font-weight: 100;
				line-height: 1;
				display: block;
				position: absolute;
				top: 5px;
				right: 8px;
				@include mq(sp) {
					font-size: 16px;
					font-size: 1.6rem;
					right: 5px;
				}
			}
			&::before {
				content: '';
				width: 100px;
				height: 100px;
				transform: rotate(45deg);
				background: rgba(0,0,0,0.5);
				position: absolute;
				display: block;
				top: -52px;
				right: -50px;
				@include mq(sp) {
					top: -68px;
					right: -68px;
				}
			}
		}
	}
}


// ==================================
// Parts Present
// ==================================
div.parts_present {
	background: #fffaf7;
	border-top: solid 1px #e3d4c4;
	border-bottom: solid 1px #e3d4c4;
	div.contentInner {
		text-align: center;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 50px 0;
		}
		@include mq(tab) {
			margin: 0 2%;
			padding: 50px 0;
		}
		@include mq(sp) {
			margin: 0 4%;
			padding: 35px 0;
		}
	}
	div.iconTop {
		border: dotted 1px #6a3906;
		font-size: 15px;
		font-size: 1.5rem;
		vertical-align: top;
		text-align: center;
		@include mq(pc) {
			display: inline-block;
			padding: 1%;
			margin-right: 15px;
		}
		@include mq(tab) {
			display: inline-block;
			padding: 1%;
			margin-right: 15px;
		}
		@include mq(sp) {
			padding: 5px 1%;
			margin-bottom: 10px;
		}
		span {
			color: #6a3906;
			font-weight: 600;
		}
	}
	h2 {
		font-family: $fontBase;
		line-height: 1.3;
		@include mq(pc) {
			font-size: 25px;
			font-size: 2.5rem;
			text-align: left;
			padding: 0;
			display: inline-block;
			width: 65%;
		}
		@include mq(tab) {
			font-size: 25px;
			font-size: 2.5rem;
			text-align: left;
			padding: 0;
			display: inline-block;
			width: 80%;
		}
		@include mq(sp) {
			font-size: 13px;
			font-size: 1.3rem;
			padding: 0;
		}
		span.strong {
			display: inline;
			@include mq(pc) {
				font-size: 35px;
				font-size: 3.5rem;
			}
			@include mq(tab) {
				font-size: 35px;
				font-size: 3.5rem;
			}
			@include mq(sp) {
				font-size: 19px;
				font-size: 1.9rem;
			}
		}
		br.sp {
			@include mq(pc) { display: none; }
			@include mq(tab) { display: none; }
		}
	}
	p.attnTxt {
		padding: 5px 0 15px;
		@include mq(pc) {
			font-size: 13px;
			font-size: 1.3rem;
			text-align: center;
		}
		@include mq(tab) {
			font-size: 13px;
			font-size: 1.3rem;
			text-align: center;
			clear: both;
		}
		@include mq(sp) {
			font-size: 1.0px;
			font-size: 1.0rem;
			text-align: left;
		}
	}
	ul.btnList {
		@extend %clearfix;
		@include mq(pc) {
			margin: 0 15%;
		}
		@include mq(tab) {
			margin: 0 10%;
		}
		@include mq(sp) {
		}
		& > * + * {
			@include mq(pc) {
				margin-left: 4%;
			}
			@include mq(tab) {
				margin-left: 4%;
			}
			@include mq(sp) {
				margin-left: 2%;
			}
		}
		& > li {
			float: left;
			@include mq(pc) {
				width: 48%;
			}
			@include mq(tab) {
				width: 48%;
			}
			@include mq(sp) {
				width: 49%;
			}
			a {
				display: block;
				border-radius: 10px;
				border: solid 2px #6a3906;
				background: $white;
				color: #6a3906;
				font-weight: 600;
				line-height: 1.3;
				text-decoration: none;
				@include mq(pc) {
					font-size: 20px;
					font-size: 2.0rem;
					padding: 20px 0;
				}
				@include mq(tab) {
					font-size: 20px;
					font-size: 2.0rem;
					padding: 20px 0;
				}
				@include mq(sp) {
					font-size: 15px;
					font-size: 1.5rem;
					padding: 5px 0;
				}
				p.infoTxt {
					font-weight: 300;
					margin-top: 5px;
					@include mq(pc) {
						font-size: 14px;
						font-size: 1.4rem;
					}
					@include mq(tab) {
						font-size: 14px;
						font-size: 1.4rem;
					}
					@include mq(sp) {
						font-size: 10px;
						font-size: 1.0rem;
					}
				}
			}
		}
	}
}

// ==================================
// parts Reform
// ==================================
div.parts_reform {
	ul.reformList {
		@extend %clearfix;
		@include mq(pc) {
			width: $widthPc;
			margin: 0 auto;
			padding: 50px 0;
		}
		@include mq(tab) {
			padding: 50px 2%;
		}
		@include mq(sp) {
			padding: 35px 4%;
		}
		& > li {
			border: solid 1px $grayL;
			background: $white;
			box-sizing: border-box;
			position: relative;
			@include mq(pc) {
				float: left;
				width: 48%;
				padding: 3%;
				margin-bottom: 3%;
				border-radius: 10px;
				&:nth-child(even) {
					margin-left: 4%;
				}
			}
			@include mq(tab) {
				float: left;
				width: 49%;
				padding: 5% 3%;
				margin-bottom: 2%;
				border-radius: 10px;
				&:nth-child(even) {
					margin-left: 2%;
				}
			}
			@include mq(sp) {
				padding: 10% 6%;
				&:nth-child(n + 2) {
					margin-top: 15px;
				}
			}
			div.titleIcon {
				background: url('/img/common/bg_zline.svg') center center no-repeat;
				background-size: contain;
				text-shadow: 2px 2px 1px $white;
				@extend .ffHalant;
				line-height: 1;
				position: absolute;
				top: 20px;
				left: 0;
				right: 0;
				margin: 0 auto;
				z-index: 10;
				@include mq(pc) {
					font-size: 24px;
					font-size: 2.4rem;
					width: 140px;
					height: 40px;
				}
				@include mq(tab) {
					font-size: 20px;
					font-size: 2.0rem;
					width: 130px;
					height: 30px;
				}
				@include mq(sp) {
					font-size: 16px;
					font-size: 1.6rem;
					width: 100px;
					height: 30px;
				}
				p.before {
					position: absolute;
					top: 0;
					left: 0;
				}
				p.after {
					position: absolute;
					bottom: 0;
					right: 0;
					padding-right: 0.3em;
				}
			}
		}
	}
	ul.imgList {
		@extend %clearfix;
		z-index: 0;
		& > li {
			float: left;
			background: center center no-repeat;
			background-size: cover;
			position: relative;
			@include mq(pc) {
				width: 46%;
			}
			@include mq(tab) {
				width: 46%;
			}
			@include mq(sp) {
				width: 47%;
			}
			&::before {
				content: ' ';
				display: block;
				padding-top: 100%;
			}
		}
		& > * + *::after {
			content: '';
			display: block;
			position: absolute;
			background: url('/img/common/icon_arrow_right_black.svg') center center no-repeat;
			background-size: contain;
			top: 50%;
		}
		@include mq(pc) {
			& > * + * {
				margin-left: 8%;
				&::after {
					width: 17%;
					height: 20px;
					margin-top: -10px;
					left: -17%;
				}
			}
		}
		@include mq(tab) {
			& > * + * {
				margin-left: 8%;
				&::after {
					width: 17%;
					height: 16px;
					margin-top: -8px;
					left: -17%;
				}
			}
		}
		@include mq(sp) {
			& > * + * {
				margin-left: 6%;
				&::after {
					width: 13%;
					height: 14px;
					margin-top: -7px;
					left: -13%;
				}
			}
		}
	}
	h3 {
		@include mq(pc) {
			text-align: center;
			font-size: 20px;
			font-size: 2.0rem;
			padding-top: 20px;
		}
		@include mq(tab) {
			font-size: 18px;
			font-size: 1.8rem;
			padding-top: 20px;
		}
		@include mq(sp) {
			font-size: 14px;
			font-size: 1.4rem;
			padding-top: 10px;
		}
	}
	p.mainTxt {
		@include mq(pc) {
			padding-top: 15px;
		}
		@include mq(tab) {
			padding-top: 10px;
		}
		@include mq(sp) {
			padding-top: 5px;
		}
	}
	ul.infoList {
		background: $grayLL;
		@include mq(pc) {
			padding: 2%;
			margin-top: 20px;
		}
		@include mq(tab) {
			padding: 5%;
			margin-top: 15px;
		}
		@include mq(sp) {
			padding: 5%;
			margin-top: 10px;
		}
	}
}
